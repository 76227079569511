import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import Modal from "../../UI/Modal/Modal.component";
import UserForm from "../../Users/Forms/UserForm/UserForm.component";

const UserFormModal = (props: { show: boolean; handleClose: () => void }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: IRootReducer) => state.users);

  const action = user && user.address.length > 0 ? "edit" : "add";

  return (
    <Modal
      closable={true}
      show={props.show}
      handleClose={props.handleClose}
      title={t(`forms.${action}User.header.title`)}
    >
      <UserForm handleClose={props.handleClose} />
    </Modal>
  );
};

export default UserFormModal;
