import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IconButton, ButtonGroup, ButtonProps, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { User } from "../../User";
import { userRemoved } from "../../../../actions/usersActions";
import { useKeeper } from "../../../../providers/KeeperProvider/KeeperProvider";

const ActionsFormatter = (props: { user: User }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { writeService } = useKeeper();
  const { enqueueSnackbar } = useSnackbar();

  const handleEvent = (
    dispatchMessage: (user: User) => any,
    successMessage: string,
    event?: (user: User) => Promise<User>
  ) => {
    if (!event) return;

    event(props.user)
      .then((res: User) => {
        dispatch(dispatchMessage(res));
        enqueueSnackbar(successMessage, { variant: "success" });
      })
      .catch((e: any) => {
        console.error("Failed to broadcast transaction: ", e);
        enqueueSnackbar(
          t("messages.keeper.broadcastFailed", {
            message: e.message,
          }),
          { variant: "error" }
        );
      });
  };

  const handleDelete = (): void => {
    return handleEvent(
      userRemoved,
      t("messages.users.userDeleted"),
      writeService?.removeUser
    );
  };

  const renderButton = (
    variant: ButtonProps["color"],
    text: string,
    onClick: () => void,
    key: string
  ) => {
    return (
      <Tooltip title={text} key={key}>
        <IconButton color={variant as any} onClick={onClick}>
          <Delete />
        </IconButton>
      </Tooltip>
    );
  };

  const buttons: JSX.Element[] = [];

  buttons.push(
    renderButton("error", t("buttons.users.delete"), handleDelete, "delete")
  );

  return <ButtonGroup size="small">{buttons}</ButtonGroup>;
};

export default ActionsFormatter;
