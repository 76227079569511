import { getKey, setKey } from "../../../helpers/localStorage";

const MAP_CENTER_LOCAL_STORAGE_KEY = "devicesMap/center";
const MAP_ZOOM_LOCAL_STORAGE_KEY = "devicesMap/zoom";

export const getCenter = () => {
  return getKey(MAP_CENTER_LOCAL_STORAGE_KEY, {
    lat: 53.776097260007624,
    lng: 20.49159059003274,
  });
};

export const setCenter = (center: google.maps.LatLng) => {
  return setKey(MAP_CENTER_LOCAL_STORAGE_KEY, {
    lat: center.lat(),
    lng: center.lng(),
  });
};

export const getZoom = () => {
  const rec = getKey(MAP_ZOOM_LOCAL_STORAGE_KEY, { zoom: 10 });

  return rec.zoom;
};

export const setZoom = (zoom: number) => {
  setKey(MAP_ZOOM_LOCAL_STORAGE_KEY, { zoom });

  return zoom;
};
