import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography } from "@mui/material";

import DevicesMap from "./DevicesMap/DevicesMap.component";

const Dashboard = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          p: 2,
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: "1em" }}>
          {t("dashboard.devicesMap.title")}
        </Typography>
        <DevicesMap />
      </Paper>
    </Grid>
  );
};

export default Dashboard;
