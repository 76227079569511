import Yup from "../../../helpers/yup-extended";
import { TFunction } from "react-i18next";

import { BLOCKCHAIN_ADDRESS_REGEXP } from "../../../helpers/regexp";

Yup.addMethod(Yup.string, "blockchainAddress", function (errorMessage) {
  return this.test("test-blockchain-address", errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: errorMessage });
    }

    const matches = value.match(BLOCKCHAIN_ADDRESS_REGEXP);

    return (
      (matches && matches.length > 0) ||
      createError({ path, message: errorMessage })
    );
  });
});

const buildSchema = (t: TFunction) => {
  return Yup.object({
    dataService: Yup.object({
      url: Yup.string().url(
        t("forms.validation.string.url", { field: "Data Service" })
      ),
      apiKey: Yup.string(),
    }),
    explorer: Yup.object({
      url: Yup.string().url(
        t("forms.validation.string.url", { field: "Explorer" })
      ),
    }),
    dappFather: Yup.object({
      address: Yup.string().blockchainAddress(
        t("forms.networkSettings.errors.dappFather.invalid")
      ),
    }),
  });
};

export default buildSchema;
