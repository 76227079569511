import { Grid } from "@mui/material";

interface SendTokenInfoTabProps {
  key: string;
  children?: React.ReactNode[];
}

const SendTokenInfoTab: React.FC<SendTokenInfoTabProps> = ({
  children,
  key,
}) => {
  return (
    <Grid
      key={key}
      container
      direction="column"
      spacing={2}
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{ minHeight: "800px" }}
    >
      {children?.map((child) => (
        <Grid item xs={12}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default SendTokenInfoTab;
