//Waves Keeper actions

export const WAVES_KEEPER_STATUS_CHANGED = "wavesKeeper/statusChanged";
export const WAVES_KEEPER_STATE_CHANGED = "wavesKeeper/stateChanged";

// Organisation verifier

export const ORG_VERIFIER_STATUS_CHANGED = "orgVerifier/statusChanged";

// Tokens

export const TOKENS_FETCHED = "tokens/tokensFetched";
export const TOKEN_CREATED = "tokens/tokenCreated";
export const TOKEN_ACTIVATED = "tokens/tokenActivated";
export const TOKEN_DEACTIVATED = "tokens/tokenDeactivated";
export const TOKEN_BURNED = "tokens/tokenBurned";
export const TOKEN_TRANSFERED = "tokens/tokenTransfered";
export const TOKEN_REISSUED = "tokens/tokenReissued";

export const TOKEN_SEND_INIT = "tokens/sendTokenInit";
export const TOKEN_SEND_CANCEL = "tokens/sendTokenCancel";

export const TOKEN_REISSUE_INIT = "tokens/reissueTokenInit";
export const TOKEN_REISSUE_CANCEL = "tokens/reissueTokenCancel";

// Token Entries

export const TOKEN_ENTRIES_FETCHED = "tokens/tokenEntriesFetched";
export const TOKEN_ENTRY_DELETED = "tokens/tokenEntryDeleted";
export const TOKEN_ENTRY_ACTIVATED = "tokens/tokenEntryActivated";
export const TOKEN_ENTRY_DEACTIVATED = "tokens/tokenEntryDeactivated";

// Users

export const USERS_FETCHED = "users/usersFetched";

export const USER_ADDED = "users/userAdded";
export const USER_REMOVED = "users/userRemoved";
export const USER_UPDATED = "users/userUpdated";

export const USER_EDIT_INIT = "users/editInit";
export const USER_EDIT_CLOSED = "users/editClosed";

// Keys

export const KEYS_FETCHED = "keys/keysFetched";

export const KEY_TRANSFERED = "keys/keyTransfered";
export const KEY_BURNED = "keys/keyBurned";

export const KEY_TRANSFER_INIT = "keys/keyTransferInit";
export const KEY_TRANSFER_CANCEL = "keys/keyTransferCancel";

// Devices

export const DEVICES_FETCHED = "devices/devicesFetched";

export const DEVICE_SHOW_INIT = "devices/showDeviceInit";
export const DEVICE_SHOW_CLOSE = "devices/showDeviceClose";

export const SHOW_DEVICE_ON_MAP_INIT = "devices/showDeviceOnMapInit";
export const SHOW_DEVICE_ON_MAP_CLOSE = "devices/showDeviceOnMapClose";

export const DEVICE_KEYS_BURN_INIT = "devices/keys/burn/Init";
export const DEVICE_KEYS_BURN_CANCELLED = "devices/keys/burn/Cancelled";
export const DEVICE_KEYS_BURN_PARTIALLY_BURNED = "devices/keys/burn/partBurned";
export const DEVICE_KEYS_BURN_FINISHED = "devices/keys/burn/burnFinish";

// Dashboard

// Devices map

export const DASHBOARD_DEVICES_MAP_MARKER_CLICKED =
  "dashboard/devicesMap/markerClicked";

export const DASHBOARD_DEVICES_MAP_INFOBOX_CLOSED =
  "dashboard/devicesMap/infoBoxClosed";
