import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { IRootReducer } from "../../../reducers";
import { devicesFetched } from "../../../actions/devicesActions";
import Renderer from "./Renderer";
import Spinner from "../../UI/Spinner/Spinner.component";
import DataServiceDisconnectedNotification from "../../UI/Notifications/DataServiceDisconnectedNotification.component";

import usePublicAddress from "../../../hooks/usePublicAddress";
import useStatusVerifier from "../../../hooks/useStatusVerifier";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";

const DevicesTable = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { read } = useBlockchainNetwork();
  const [fetched, setFetched] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useSelector((state: IRootReducer) => state.devices);

  const address = usePublicAddress();
  const orgVerified = useStatusVerifier();

  const [currentAddress, setCurrentAddress] =
    useState<string | undefined>(address);

  const fetchDevices = useCallback(
    (addr: string) => {
      if (!read) {
        setFetched(true);
        return;
      }

      read
        .getDevices({
          page: 1,
          perPage: 10,
          order: "asc",
          orderBy: "address",
          keysOwner: addr,
        })
        .then(({ devices, meta }) => {
          dispatch(devicesFetched(devices, meta));
          setFetched(true);
        })
        .catch((e) => {
          enqueueSnackbar(
            t("messages.devices.fetchFailed", {
              message: e.message,
            }),
            {
              variant: "error",
            }
          );
        })
        .finally(() => {
          setFetched(true);
        });
    },
    [dispatch, t, read, enqueueSnackbar]
  );

  useEffect(() => {
    if (!orgVerified || !currentAddress) return;

    fetchDevices(currentAddress);
  }, [fetchDevices, orgVerified, currentAddress]);

  useEffect(() => {
    if (address === currentAddress) return;

    setCurrentAddress(address);
  }, [address, currentAddress]);

  let content: JSX.Element = <Spinner />;

  if (!read) {
    content = <DataServiceDisconnectedNotification />;
  } else if (fetched) {
    content = <Renderer devices={Object.values(data)} />;
  }

  return content;
};

export default DevicesTable;
