import { IReissueTokenForm } from "./ReissueTokenForm.component";

const buildTxData = (form: IReissueTokenForm): WavesKeeper.TReissueTxData => {
  return {
    type: 5,
    data: {
      assetId: form.assetId,
      quantity: form.amount,
      reissuable: true,
      fee: {
        assetId: "WAVES",
        tokens: 0.009,
      },
    },
  };
};

export default buildTxData;
