import {
  KEYS_FETCHED,
  KEY_BURNED,
  KEY_TRANSFERED,
  KEY_TRANSFER_CANCEL,
  KEY_TRANSFER_INIT,
} from "./types";

import { Key, KeyCollection } from "../components/Keys/Key";
import { PaginationItemsMeta } from "../helpers/helpers";

const defaultKeyAction = (action: string, key: Key) => {
  return {
    type: action,
    payload: { key },
  };
};

export const keysFetched = (keys: KeyCollection, meta: PaginationItemsMeta) => {
  return {
    type: KEYS_FETCHED,
    payload: { keys, meta },
  };
};

export const keyBurned = (key: Key) => {
  return defaultKeyAction(KEY_BURNED, key);
};

export const keyTransferInit = (key: Key) => {
  return defaultKeyAction(KEY_TRANSFER_INIT, key);
};

export const keyTransferCancel = () => {
  return {
    type: KEY_TRANSFER_CANCEL,
  };
};

export const keyTransfered = (key: Key) => {
  return defaultKeyAction(KEY_TRANSFERED, key);
};
