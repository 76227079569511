import { ButtonGroup } from "@mui/material";

import { Device } from "../Device";
import ShowMapButton from "./Actions/ShowMapButton";
import BurnKeysButton from "./Actions/BurnKeysButton";

const renderActions = (row: Device): JSX.Element => {
  return (
    <ButtonGroup size="small">
      <ShowMapButton device={row} />
      <BurnKeysButton device={row} />
    </ButtonGroup>
  );
};

export default renderActions;
