import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { userEditInit, userEditClosed } from "../../actions/usersActions";
import UsersTable from "./UsersTable/UsersTable.component";
import UserFormModal from "../Modals/UserFormModal/UserFormModal.component";
import { useTranslation } from "react-i18next";
import { IRootReducer } from "../../reducers";

const Users = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootReducer) => state.users);

  const handleAddUserClicked = () => {
    dispatch(
      userEditInit({
        address: "",
        mobileId: "",
        note: "",
      })
    );
  };

  const handleAddModalClosed = () => {
    dispatch(userEditClosed());
  };

  return (
    <React.Fragment>
      <br />
      <UserFormModal show={!!user} handleClose={handleAddModalClosed} />
      <Grid item xs={12}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t("users.title")}
          </Typography>
          <UsersTable showModal={handleAddUserClicked} />
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default Users;
