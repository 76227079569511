import { TFunction } from "i18next";
import * as Yup from "yup";

const buildValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    amount: Yup.number()
      .required(
        t("forms.validation.required", {
          field: t("forms.reissueToken.fields.amount.label"),
        })
      )
      .positive(
        t("forms.validation.number.positive", {
          field: t("forms.reissueToken.fields.amount.label"),
        })
      ),
  });
};

export default buildValidationSchema;
