import { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  FormGroup,
  ButtonGroup,
} from "@mui/material";
import { QrCodeScanner } from "@mui/icons-material";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { BLOCKCHAIN_ADDRESS_REGEXP } from "../../../helpers/regexp";
import QrScannerModal from "../../Modals/QrScannerModal/QrScannerModal.component";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";

export interface Asset {
  assetId: string;
  amount: number;
}

export interface ITransferForm extends Asset {
  address: string;
}

const TransferForm = (props: {
  asset: Asset;
  callback: (res: any, values: ITransferForm) => void;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const { writeService } = useKeeper();
  const [scannerEnabled, setScannerEnabled] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .required(
        t("forms.validation.required", {
          field: t("forms.transferKey.fields.address.label"),
        })
      )
      .matches(
        BLOCKCHAIN_ADDRESS_REGEXP,
        t("forms.validation.blockchainAddress.invalid", {
          field: t("forms.transferKey.fields.address.label"),
        })
      ),
  });

  const onSubmit = (
    values: ITransferForm,
    formikProps: FormikHelpers<ITransferForm>
  ) => {
    if (!writeService) return;

    formikProps.setSubmitting(true);
    writeService
      .transferAsset(values)
      .then((res: any) => {
        props.callback(res, values);
      })
      .catch((e) => {
        enqueueSnackbar(
          t("messages.keeper.broadcastFailed", {
            message: e.message,
          }),
          { variant: "error" }
        );
      });
    formikProps.setSubmitting(false);
  };

  const onQrScanButtonClick = () => {
    setScannerEnabled(!scannerEnabled);
  };

  const onQrScanError = (error: any) => {
    enqueueSnackbar(
      t("messages.qrScanner.failed", { message: error.message }),
      { variant: "error" }
    );
  };

  const onQrScanCompleted = (result: string, setFieldValue: any) => {
    setFieldValue("address", result);
    enqueueSnackbar(t("messages.qrScanner.completed"), { variant: "info" });
    setScannerEnabled(false);
  };

  const buildScanner = (setFieldValue: any) => {
    if (!scannerEnabled) return <></>;

    return (
      <QrScannerModal
        onScanned={(result) => onQrScanCompleted(result, setFieldValue)}
        onError={onQrScanError}
        onClose={onQrScanButtonClick}
      />
    );
  };

  return (
    <Formik
      initialValues={{ ...props.asset, address: "" }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
        dirty,
        isValid,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControl variant="outlined">
              <InputLabel>
                {t("forms.sendToken.fields.address.label")}
              </InputLabel>
              <OutlinedInput
                name="address"
                type="text"
                value={values.address}
                onChange={handleChange}
                error={!errors.address}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={onQrScanButtonClick}>
                      <QrCodeScanner />
                    </IconButton>
                  </InputAdornment>
                }
                label={t("forms.sendToken.fields.address.label")}
                placeholder={t("forms.sendToken.fields.address.placeholder")}
              />
              {errors.address && (
                <FormHelperText error>{errors.address}</FormHelperText>
              )}
            </FormControl>
          </FormGroup>

          {buildScanner(setFieldValue)}
          <br />
          <ButtonGroup>
            <Button
              type="submit"
              variant="contained"
              disabled={!dirty || !isValid || isSubmitting}
            >
              {t("forms.transferKey.buttons.submit")}
            </Button>
            <Button variant="contained" color="error" onClick={props.onClose}>
              {t("forms.transferKey.buttons.cancel")}
            </Button>
          </ButtonGroup>
        </form>
      )}
    </Formik>
  );
};

export default TransferForm;
