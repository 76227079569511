import React from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Modal from "../../UI/Modal/Modal.component";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";

const useSkeyKeeper = !!process.env.REACT_APP_USE_SKEY_KEEPER;
const keeperName = useSkeyKeeper ? "Skey" : "Waves";

const rejectedContent = (t: TFunction) => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="h3">
          {t("keeperDetector.rejected.header", { keeper: keeperName })}
        </Typography>
        <Typography variant="h6">
          {t("keeperDetector.rejected.instructionsHeader")}
        </Typography>
      </div>
      <ul>
        <li>
          {t("keeperDetector.rejected.instructions.step1", {
            keeper: keeperName,
          })}{" "}
        </li>
        <li>{t("keeperDetector.rejected.instructions.step2")}</li>
        <li>
          {t("keeperDetector.rejected.instructions.step3", {
            host: window.location.host,
          })}
        </li>
        <li>{t("keeperDetector.rejected.instructions.step4")}</li>
        <li>{t("keeperDetector.rejected.instructions.step5")}</li>
      </ul>
    </div>
  );
};

const notFoundContent = (t: TFunction) => {
  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h3">
        {t("keeperDetector.notFound.header", { keeper: keeperName })}
      </Typography>
      <Typography variant="h6">
        {t("keeperDetector.notFound.content1", { keeper: keeperName })}
      </Typography>
      {useSkeyKeeper ?? (
        <>
          <Typography variant="h6">
            {t("keeperDetector.notFound.content2", { keeper: keeperName })}
          </Typography>
          <a href="https://addons.mozilla.org/pl/firefox/addon/waves-keeper/">
            Firefox
          </a>
          <br />
          <a href="https://chrome.google.com/webstore/detail/waves-keeper/lpilbniiabackdjcionkobglmddfbcjo">
            Chrome
          </a>
        </>
      )}
    </div>
  );
};

const pendingContent = (t: TFunction) => {
  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h3">
        {t("keeperDetector.pending.content1", { keeper: keeperName })}
      </Typography>
      <Typography variant="h6">
        {t("keeperDetector.pending.content2")}
      </Typography>
    </div>
  );
};

const KeeperDetectorModal = (props: any) => {
  const { t } = useTranslation();
  const { status } = useKeeper();

  let content = null;

  switch (status) {
    case "refused":
      content = rejectedContent(t);
      break;
    case "not_available":
      content = notFoundContent(t);
      break;
    case "loading":
      content = pendingContent(t);
      break;
  }

  return (
    <Modal closable={false} show={content !== null}>
      {content}
    </Modal>
  );
};

export default KeeperDetectorModal;
