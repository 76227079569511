import { User, UserCollection } from "../components/Users/User";

import {
  USERS_FETCHED,
  USER_REMOVED,
  USER_UPDATED,
  USER_EDIT_INIT,
  USER_EDIT_CLOSED,
} from "./types";

const defaultUserAction = (user: User, action: string) => {
  return {
    type: action,
    payload: { user },
  };
};

export const usersFetched = (users: UserCollection) => {
  return {
    type: USERS_FETCHED,
    payload: {
      users,
    },
  };
};

export const userUpdated = (user: User) => {
  return defaultUserAction(user, USER_UPDATED);
};

export const userRemoved = (user: User) => {
  return defaultUserAction(user, USER_REMOVED);
};

export const userEditInit = (user: User) => {
  return defaultUserAction(user, USER_EDIT_INIT);
};

export const userEditClosed = () => {
  return { type: USER_EDIT_CLOSED };
};
