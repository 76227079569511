import Yup from "../../../../helpers/yup-extended";
import { Recipient } from "./SendTokenForm.component";
import { calculateTotalAmount } from "./helpers";

import { BLOCKCHAIN_ADDRESS_REGEXP } from "../../../../helpers/regexp";
import { TFunction } from "i18next";

const buildValidationSchema = (
  balance: number,
  t: TFunction,
  publicState: WavesKeeper.IPublicStateResponse | null
) => {
  const tError = (e: string, obj?: any) => {
    return t("forms.sendToken.errors." + e, obj);
  };

  return Yup.object().shape({
    recipients: Yup.array()
      .of(
        Yup.object().shape({
          address: Yup.string()
            .matches(BLOCKCHAIN_ADDRESS_REGEXP, tError("address.invalid"))
            .required(
              t("forms.validation.required", {
                field: t("forms.sendToken.fields.address.label"),
              })
            )
            .addressChainId(
              publicState?.network.code,
              tError("address.invalidChainId")
            ),
          amount: Yup.number()
            .positive(
              t("forms.validation.number.positive", {
                field: t("forms.sendToken.fields.amount.label"),
              })
            )
            .required(
              t("forms.validation.required", {
                field: t("forms.sendToken.fields.amount.label"),
              })
            ),
        })
      )
      .required()
      .min(1, tError("recipients.min", { min: 1 }))
      .max(100, tError("recipients.max", { max: 100 }))
      .test("tooManyTokens", tError("tokens.tooMany"), (value) => {
        if (value && value.length > 0) {
          return calculateTotalAmount(value as Recipient[]) <= balance;
        } else {
          return true;
        }
      })
      .test("recipientsUniqueness", tError("recipients.unique"), (value) => {
        if (!value || value.length === 0) return true;

        const recipientsAddresses = (value as Recipient[]).map(
          (rec: Recipient) => rec.address
        );

        return new Set(recipientsAddresses).size === recipientsAddresses.length;
      }),
  });
};

export default buildValidationSchema;
