import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { LOCALES, DEFAULT_LOCALE } from "../helpers/locale/date-fns";

const useFormattedTime = (timestamp: number | Date) => {
  const { i18n } = useTranslation();

  return format(timestamp, "PPPpp", {
    locale: LOCALES[i18n.language] || DEFAULT_LOCALE,
  });
};

export default useFormattedTime;
