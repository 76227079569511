import { useState, useReducer, Reducer } from "react";
import { TableChangeState } from "react-bootstrap-table-next";

interface ITableHandlerProps {
  defaults?: {
    page?: number;
    perPage?: number;
    order?: string;
    orderBy?: string;
  };
}

interface IQueryParamsReducer {
  search: string | undefined;
  page: number;
  perPage: number;
  order: "asc" | "desc";
  orderBy: string;
}

function useDataServiceTableHandler<T>(props?: ITableHandlerProps) {
  const [lastAction, setLastAction] = useState<string | undefined>();

  const initialState: IQueryParamsReducer = {
    ...(props?.defaults || {}),
    search: undefined,
    page: 1,
    perPage: 10,
    order: "asc",
    orderBy: "name",
  };

  const reducer = (state: any, action: any) => {
    setLastAction(action.type);
    switch (action.type) {
      case "searchChanged":
        return { ...state, search: action.search, page: 1 };
      case "searchNotChanged":
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const [queryParams, dispatchParams] = useReducer<
    Reducer<IQueryParamsReducer, any>
  >(reducer, initialState);

  const tableChangedEventToDispatchArgs = (e: TableChangeState<T>) => {
    const dispatchArgs: { [key: string]: string | number } = {
      order: e.sortOrder,
      orderBy: e.sortField,
      page: e.page,
      perPage: e.sizePerPage,
    };

    Object.keys(dispatchArgs).forEach((key: string) => {
      if (dispatchArgs[key]) return;

      delete dispatchArgs[key];
    });

    return dispatchArgs;
  };

  const tableChanged = (e: TableChangeState<T> & { searchText?: string }) => {
    const currentSearch = e.searchText === "" ? undefined : e.searchText;
    const searchChanged = currentSearch !== queryParams.search;

    if (searchChanged) {
      dispatchParams({
        type: "searchChanged",
        search: currentSearch,
      });
    } else {
      dispatchParams({
        type: "searchNotChanged",
        payload: {
          ...queryParams,
          ...tableChangedEventToDispatchArgs(e),
        },
      });
    }
  };

  return { lastAction, queryParams, tableChanged };
}

export default useDataServiceTableHandler;
