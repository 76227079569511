import { AnyAction } from "redux";

import {
  DASHBOARD_DEVICES_MAP_MARKER_CLICKED,
  DASHBOARD_DEVICES_MAP_INFOBOX_CLOSED,
} from "../actions/types";

export interface IDashboardReducer {
  devicesMap: {
    activeMarker: string;
  };
}

const initialValue: IDashboardReducer = {
  devicesMap: {
    activeMarker: "",
  },
};

const dashboardReducer = (
  state: IDashboardReducer = initialValue,
  action: AnyAction
) => {
  switch (action.type) {
    case DASHBOARD_DEVICES_MAP_MARKER_CLICKED:
      return {
        ...state,
        devicesMap: {
          activeMarker: action.payload.address,
        },
      };
    case DASHBOARD_DEVICES_MAP_INFOBOX_CLOSED:
      return {
        ...state,
        devicesMap: {
          activeMarker: "",
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
