import { GraphQLClient } from "graphql-request";
import { RequestDocument } from "graphql-request/dist/types";
import { Device, DeviceCollection } from "../../components/Devices/Device";
import { Key, KeyCollection } from "../../components/Keys/Key";
import * as queries from "../../api/dataService/queries";
import { TFunction } from "react-i18next";
import { ApolloError } from "apollo-server-errors";

interface PaginationQueryParams {
  take: number;
  skip: number;
  [key: string]: any;
}

const pageParamsToTake = (params: PaginationParams): PaginationQueryParams => {
  const variables: any = { ...params };

  variables.take = params.perPage;
  variables.skip = (params.page - 1) * params.perPage;
  delete variables.page;
  delete variables.perPage;

  return variables;
};

interface PointParams {
  lat: number;
  lng: number;
}

interface GeoSearchInput {
  bottomLeft: PointParams;
  upperRight: PointParams;
}

interface PaginationParams {
  page: number;
  perPage: number;
  order?: "asc" | "desc";
  orderBy?: string;
}

interface ObjectsCollection<T> {
  objects: T[];
  meta: {
    total: number;
    skip?: number;
    take?: number;
  };
}

// Keys

interface KeysFilterParams {
  issuer?: string;
  owner?: string;
  name?: string;
  device?: string;
  burned?: boolean;
}

export interface KeysPaginationParams extends PaginationParams {
  filter?: KeysFilterParams;
  search?: string;
}

interface DevicesFilterParams {
  supplier?: string;
  owner?: string;
  visible?: boolean;
  active?: boolean;
  connected?: boolean;
  whitelisted?: boolean;
}

export interface DevicesPaginationParams extends PaginationParams {
  filter?: DevicesFilterParams;
  geoSearch?: GeoSearchInput;
  keysOwner?: string;
  search?: string;
}

export default class DataServiceReadService {
  private abortController: AbortController;
  private graphQlClient: GraphQLClient;

  constructor(
    public url: string,
    public translate: TFunction,
    public apiKey?: string
  ) {
    if (!this.url || this.url === "") {
      throw new Error("invalid Data Service URL");
    }

    this.abortController = new AbortController();
    const headers = new Headers();

    if (this.apiKey) {
      headers.append("x-api-key", this.apiKey);
    }

    this.graphQlClient = new GraphQLClient(this.graphqlUrl, {
      signal: this.abortController.signal,
      headers,
    });
  }

  get graphqlUrl(): string {
    return this.url + (this.url.match("/$") ? "" : "/") + "graphql";
  }

  // keys

  getKeys(params: KeysPaginationParams): {
    request: Promise<{ keys: KeyCollection; meta: { total: number } }>;
    abort: AbortController;
  } {
    const variables: any = pageParamsToTake(params);

    const { request, controller } = this.cancellableRequest(
      queries.getKeysQuery,
      variables
    );

    return {
      request: request
        .then((data: { keys: ObjectsCollection<Key> }) => {
          const collection: KeyCollection = {};

          data.keys.objects.forEach((keyData: Key) => {
            collection[keyData.assetId] = keyData;
          });

          return {
            keys: collection,
            meta: data.keys.meta,
          };
        })
        .catch((e: any) => {
          return this.handleGraphQlError(e);
        }),
      abort: controller,
    };
  }

  // Devices

  // getDevices

  async getDevices(
    params: DevicesPaginationParams
  ): Promise<{ devices: DeviceCollection; meta: { total: number } }> {
    const variables = pageParamsToTake(params);

    const { request } = this.cancellableRequest(
      queries.getDevicesQuery,
      variables
    );

    return request
      .then((data: { devices: ObjectsCollection<Device> }) => {
        const collection: DeviceCollection = {};

        data.devices.objects.forEach((deviceData: Device) => {
          collection[deviceData.address] = deviceData;
        });

        return { devices: collection, meta: data.devices.meta };
      })
      .catch((e: any) => {
        return this.handleGraphQlError(e);
      });
  }

  // GetDevice

  async getDevice(address: string): Promise<Device> {
    const { request } = this.cancellableRequest(
      queries.getDeviceQuery(address)
    );

    return request
      .then((data: any) => {
        return data.device as Device;
      })
      .catch((e: any) => {
        return this.handleGraphQlError(e);
      });
  }

  // GetDeviceKeys

  async getDeviceKeys(owner: string, device: string): Promise<KeyCollection> {
    const { request } = this.cancellableRequest(queries.getDeviceKeysQuery, {
      owner,
      device,
    });

    return request
      .then((data: any) => {
        const collection: KeyCollection = {};

        data.keys.objects.forEach((keyData: Key) => {
          collection[keyData.assetId] = keyData;
        });

        return collection;
      })
      .catch((e: any) => {
        return this.handleGraphQlError(e);
      });
  }

  private cancellableRequest(
    document: RequestDocument,
    variables?: any
  ): { request: Promise<any>; controller: AbortController } {
    return {
      request: this.graphQlClient.request(document, variables),
      controller: this.abortController,
    };
  }

  private handleGraphQlError = (e: {
    response: { errors: ApolloError[] };
  }): any => {
    if (!e.response) throw e;

    const { errors } = e.response;

    console.error(
      "[dataService] Failed to fetch: ",
      errors.map((err: ApolloError) => err.message).join(", ")
    );
    console.error(errors);

    throw new Error(this.translate("messages.dataService.fetchFailed"));
  };
}
