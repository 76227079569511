import { getIn, FormikErrors } from "formik";
import { ISendTokenForm } from "../SendTokenForm.component";

export const isRecipientAttrValid = (
  errors: FormikErrors<ISendTokenForm>,
  index: number,
  attr: string
) => {
  switch (typeof errors.recipients) {
    case "object":
      const error = getIn(errors, `recipients[${index}].${attr}`);
      return error === undefined;
    default:
      return true;
  }
};
