import { Theme } from "@mui/material";

import { Key } from "../Key";
import { pickExpirationStatus } from "../../../helpers/keyExpirationThreshold";

const keyRowFormatter = (key: Key, theme: Theme) => {
  const keyStatus = pickExpirationStatus(key.validTo);

  let backgroundColor;

  switch (keyStatus) {
    case "warning":
      backgroundColor = theme.palette.warning.light;
      break;
    case "alert":
      backgroundColor = theme.palette.error.light;
      break;
    case "expired":
      backgroundColor = theme.palette.action.disabledBackground;
      break;
    default:
      backgroundColor = theme.palette.background.paper;
      break;
  }

  return {
    backgroundColor,
    color: theme.palette.getContrastText(backgroundColor),
  };
};

export default keyRowFormatter;
