import "./notification.css";

import { Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

interface NotificationProps {
  title: string;
  description?: string;
  Icon?: SvgIconComponent;
  iconColor?: string;
  children?: React.ReactNode;
}

const Notification: React.FC<NotificationProps> = ({
  title,
  description,
  Icon,
  iconColor,
  children,
}) => {
  let renderedIcon;

  if (Icon) {
    renderedIcon = (
      <Icon color={(iconColor as any) || "primary"} sx={{ fontSize: 72 }} />
    );
  }

  return (
    <div className="notice">
      {renderedIcon && <div className="icon">{renderedIcon}</div>}
      <Typography variant="h4" sx={{ marginBottom: "10px" }}>
        {title}
      </Typography>
      {description && <Typography variant="body1">{description}</Typography>}
      {children}
    </div>
  );
};

export default Notification;
