import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import { Device } from "../../Device";

const ConnectedFormatter = (props: { device: Device }) => {
  const { t } = useTranslation();

  const label = t(
    `tables.devices.enums.connected.${props.device.connected.toString()}`
  );

  return (
    <Chip
      variant="filled"
      size="small"
      label={label}
      color={props.device.connected ? "success" : "error"}
    />
  );
};

export default ConnectedFormatter;
