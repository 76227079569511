import React, { useCallback } from "react";

import { NetworkConfigs, BlockchainNetworkConfig } from ".";
import { useKeeper } from "../KeeperProvider/KeeperProvider";
import useRemoteRequest from "./useRemoteRequest";

export const DEFAULT_NETWORK_CONFIGS: NetworkConfigs = {
  custom: {
    chainId: "R",
    dataService: {
      url: "",
    },
    explorer: {
      url: "",
    },
    dappFather: {
      address: "",
    },
  },
};

const DATA_SERVICE_API_KEYS: { [network: string]: string | undefined } = {
  mainnet: process.env.REACT_APP_DATA_SERVICE_MAINNET_API_KEY,
  testnet: process.env.REACT_APP_DATA_SERVICE_TESTNET_API_KEY,
  devnet: process.env.REACT_APP_DATA_SERVICE_DEVNET_API_KEY,
};

const REMOTE_CONFIGS_URL =
  "https://raw.githubusercontent.com/skey-network/skey-client-config/master/network-configs.json";

const LOCAL_STORAGE_KEY = "blockchain.config.custom";

const getLocalCustomNetworkConfig = (): BlockchainNetworkConfig => {
  const localConfig = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!localConfig) return DEFAULT_NETWORK_CONFIGS.custom;

  return JSON.parse(localConfig);
};

const setLocalCustomNetworkConfig = (form: BlockchainNetworkConfig) => {
  return localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(form));
};

const useNetworkPicker = () => {
  const { publicState } = useKeeper();

  const configsRequest = useRemoteRequest(REMOTE_CONFIGS_URL);

  const [networks, setNetworks] = React.useState<NetworkConfigs>(
    DEFAULT_NETWORK_CONFIGS
  );
  const [currentNetwork, setCurrentNetwork] = React.useState<
    BlockchainNetworkConfig | undefined
  >(getLocalCustomNetworkConfig());
  const [currentNetworkName, setCurrentNetworkName] =
    React.useState<string | undefined>("custom");

  const clearNetwork = useCallback(() => {
    setCurrentNetwork(undefined);
    setCurrentNetworkName(undefined);
  }, []);

  React.useEffect(() => {
    const { status, data, error } = configsRequest.state;
    if (status !== "fetched") return;

    if (!error) {
      if (!data) return;
      setNetworks((networks) => ({
        ...networks,
        ...data,
      }));
    } else {
      clearNetwork();
      console.error("[useNetworkPicker] failed to fetch: ", error);
    }
  }, [configsRequest.state, clearNetwork]);

  React.useEffect(() => {
    const savedCustomConfig = getLocalCustomNetworkConfig();
    setNetworks((networks) => ({ ...networks, custom: savedCustomConfig }));
  }, []);

  React.useEffect(() => {
    if (!publicState) {
      clearNetwork();
      return;
    }

    const currentChainId = publicState?.network?.code;

    if (!currentChainId) return;

    const newNetwork = Object.entries(networks).find(([name, config]) => {
      return config.chainId === currentChainId;
    });

    if (newNetwork) {
      const [name, config] = newNetwork;
      config.dataService.apiKey = DATA_SERVICE_API_KEYS[name];
      setCurrentNetwork(config);
      setCurrentNetworkName(name);
    } else {
      const customNetwork = { ...networks.custom };
      customNetwork.chainId = currentChainId;
      setCurrentNetwork(customNetwork);
      setCurrentNetworkName("custom");
    }
  }, [networks, publicState, clearNetwork]);

  const setCustomNetwork = (form: BlockchainNetworkConfig) => {
    setNetworks((networks) => ({
      ...networks,
      custom: { ...form },
    }));
    setLocalCustomNetworkConfig(form);

    if (currentNetworkName === "custom") {
      setCurrentNetwork((current) => ({ ...current, ...form }));
    }
  };

  return {
    networks,
    currentNetwork,
    setCustomNetwork,
    currentNetworkName,
    remoteConfigRequestStatus: configsRequest.state.status,
    refetchRemoteConfigs: configsRequest.refetch,
  };
};

export default useNetworkPicker;
