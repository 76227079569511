import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import DevicesTable from "./DevicesTable/DevicesTable.component";

const Devices = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h4">{t("devices.title")}</Typography>
          <DevicesTable />
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default Devices;
