import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableChangeType, TableChangeState } from "react-bootstrap-table-next";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Row } from "react-table";

import { Device } from "../Device";
import buildColumns from "./Columns";
import { DevicesPaginationParams } from "../../../providers/BlockchainNetworkProvider/DataServiceReadService";
import { devicesFetched } from "../../../actions/devicesActions";
import { IRootReducer } from "../../../reducers";
import useDataServiceTableHandler from "../../../hooks/useDataServiceTableHandler";
import usePublicAddress from "../../../hooks/usePublicAddress";
import { searchToSearchQuery } from "../../../helpers/helpers";
import renderTable from "../../UI/MUITable/Renderer";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import { useLayout } from "../../../providers/LayoutProvider/LayoutProvider";
import keyRowFormatter from "../../Keys/helpers/keyRowFormatter";

const Renderer = (props: { devices: Device[]; total?: number }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const address = usePublicAddress();
  const { read } = useBlockchainNetwork();
  const { theme } = useLayout();

  const { meta } = useSelector((state: IRootReducer) => state.devices);
  const { enqueueSnackbar } = useSnackbar();

  const { lastAction, queryParams, tableChanged } =
    useDataServiceTableHandler<Device>({
      defaults: {
        order: "asc",
        orderBy: "name",
      },
    });

  const fetchDevices = useCallback(
    (params: DevicesPaginationParams) => {
      if (!read) return;

      read
        .getDevices(params)
        .then(({ devices, meta }) => {
          dispatch(devicesFetched(devices, meta));
        })
        .catch((error) => {
          if (!error.response.errors) return;

          enqueueSnackbar(
            t("messages.devices.fetchFailed", {
              message: error.response.errors
                .map((e: any) => e.message)
                .join(", "),
            }),
            { variant: "error" }
          );
        });
    },
    [dispatch, t, read, enqueueSnackbar]
  );

  // Formatting of table rows
  // Devices with expired keys should be marked
  // And devices with keys expiring soon should be marked differently too
  const rowFormatter = (row: Row<Device>) => {
    const device = row.original;

    if (!device.key) {
      return {};
    }

    return keyRowFormatter(device.key, theme);
  };

  const buildQueryParams = useCallback(() => {
    return {
      ...queryParams,
      search: searchToSearchQuery(queryParams.search),
      keysOwner: address,
    };
  }, [queryParams, address]);

  useEffect(() => {
    if (lastAction !== "searchChanged") return;

    const debounceFetch = debounce(() => {
      fetchDevices(buildQueryParams());
    }, 500);

    debounceFetch();

    return () => {
      debounceFetch.cancel();
    };
  }, [lastAction, buildQueryParams, fetchDevices]);

  useEffect(() => {
    if (lastAction !== "searchNotChanged") return;

    fetchDevices(buildQueryParams());
  }, [lastAction, buildQueryParams, fetchDevices]);

  const handleTableChange = (
    type: TableChangeType,
    e: TableChangeState<Device> & { searchText?: string }
  ): void => {
    tableChanged(e);
  };

  return renderTable({
    mode: "server",
    data: props.devices,
    columns: buildColumns(dispatch, t, read),
    onTableChange: handleTableChange as any,
    state: {
      page: queryParams.page,
      sizePerPage: queryParams.perPage,
      totalSize: meta.total,
      orderBy: queryParams.orderBy,
      order: queryParams.order,
    },
    options: {
      searchInput: true,
      searchInputLabel: t("tables.search.placeholder"),
    },
    rowFormatter,
  });
};

export default Renderer;
