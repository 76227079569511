import * as Transactions from "@waves/waves-transactions";
import { WalletStatus } from ".";

export default class KeeperService {
  private KeeperPath = process.env.REACT_APP_USE_SKEY_KEEPER
    ? "SkeyKeeper"
    : "WavesKeeper";
  private publicState: WavesKeeper.IPublicStateResponse | null = null;

  constructor(publicState: WavesKeeper.IPublicStateResponse | null) {
    this.publicState = publicState;
  }

  get keeper(): WavesKeeper.TWavesKeeperApi | null {
    return (window as any)[this.KeeperPath] ?? null;
  }

  async waitForTx(txId: string) {
    if (!this.keeper || !this.publicState) {
      console.error("cannot wait for tx");
      await this.delay(1000);
      return;
    }

    await Transactions.waitForTx(txId, {
      apiBase: this.publicState.network.server,
    });
  }

  delay(timeout: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  async connect(
    setStatus: (status: WalletStatus) => void,
    setPublicState: (state: WavesKeeper.IPublicStateResponse | null) => void
  ) {
    if (!this.keeper) {
      console.error("keeper not available");
      setStatus("not_available");
      return false;
    }

    const result = await this.keeper.publicState().catch((e) => {
      console.error("Failed to fetch public State: ", e);
      setStatus("not_available");
      return null;
    });

    setStatus(result ? "connected" : "refused");

    if (!result) return false;
    setPublicState(result);

    this.keeper?.on("update", setPublicState);

    return true;
  }

  waitForKeeper(timeout: number) {
    const interval = 100;
    const start = Date.now();

    return new Promise<boolean>((resolve) => {
      const handle = setInterval(async () => {
        if (this.keeper?.initialPromise) {
          await this.keeper.initialPromise;

          clearInterval(handle);
          return resolve(true);
        }

        const timeDiff = Date.now() - start;

        if (timeDiff >= timeout) {
          clearInterval(handle);
          return resolve(false);
        }
      }, interval);
    });
  }
}
