import { useTranslation } from "react-i18next";
import { formatRelative } from "date-fns";
import { LOCALES, DEFAULT_LOCALE } from "../helpers/locale/date-fns";

const useFormattedRelativeTime = (
  timestamp: number | Date,
  reference: number | Date
) => {
  const { i18n } = useTranslation();

  return formatRelative(timestamp, reference, {
    locale: LOCALES[i18n.language] || DEFAULT_LOCALE,
  });
};

export default useFormattedRelativeTime;
