import { Box, Button, TextField } from "@mui/material";
import React from "react";
import { TableInstanceProps } from "./ITable";
import AddIcon from "@mui/icons-material/Add";

const CustomTableTop: React.FC<TableInstanceProps> = ({
  instance,
  options,
  mode,
  onTableChange,
}) => {
  const [search, setSearch] = React.useState("");

  const onSearchChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setSearch(e.target.value);

    if (mode === "local") {
      instance.setGlobalFilter(e.target.value);
    } else {
      onTableChange("filter", { searchText: e.target.value });
    }
  };

  if (!options.addItemButton && !options.searchInput) return null;

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {options.addItemButton ? (
        <Button
          variant="outlined"
          endIcon={<AddIcon />}
          size="large"
          sx={{ height: "40px", minWidth: "max-content", marginTop: "5px" }}
          onClick={options.onAddItem}
        >
          {options.addItemButtonLabel ?? "Add Item"}
        </Button>
      ) : (
        <span />
      )}
      {options.searchInput ? (
        <TextField
          variant="outlined"
          value={search}
          onChange={onSearchChange}
          label={options.searchInputLabel ?? "Search"}
          size="small"
          sx={{
            maxWidth: "400px",
            width: "100%",
            marginTop: "5px",
            marginLeft: "10px",
          }}
        />
      ) : (
        <span />
      )}
    </Box>
  );
};

export default CustomTableTop;
