/// <reference types="google.maps" />

import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { GOOGLE_MAPS_DARK_MODE_STYLE } from "../../../helpers/MapStyles";
import { useLayout } from "../../../providers/LayoutProvider/LayoutProvider";

import { Device } from "../Device";

const DeviceMap = (props: { device: Device }) => {
  const { darkMode } = useLayout();

  const center = {
    lat: props.device.lat as number,
    lng: props.device.lng as number,
  };
  const mapContainerStyle = { width: "100%", height: "300px" };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const onLoad = (map: google.maps.Map) => {
    setTimeout(() => {
      map.panTo(center);
    }, 1);
  };

  return isLoaded ? (
    <GoogleMap
      id="deviceMap"
      mapContainerStyle={mapContainerStyle}
      zoom={10}
      onLoad={onLoad}
      options={{
        styles: darkMode ? GOOGLE_MAPS_DARK_MODE_STYLE : null,
      }}
    >
      {<Marker position={center} />}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default DeviceMap;
