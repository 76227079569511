import { combineReducers } from "redux";
import wavesKeeperReducer, { IWavesKeeperReducer } from "./wavesKeeperReducer";
import tokensReducer, { ITokensReducer } from "./tokensReducer";
import usersReducer, { IUsersReducer } from "./usersReducer";
import keysReducer, { IKeysReducer } from "./keysReducer";
import devicesReducer, { IDevicesReducer } from "./devicesReducer";
import dashboardReducer, { IDashboardReducer } from "./dashboardReducer";

export interface IRootReducer {
  wavesKeeper: IWavesKeeperReducer;
  tokens: ITokensReducer;
  users: IUsersReducer;
  keys: IKeysReducer;
  devices: IDevicesReducer;
  dashboard: IDashboardReducer;
}

export default combineReducers({
  wavesKeeper: wavesKeeperReducer,
  tokens: tokensReducer,
  users: usersReducer,
  keys: keysReducer,
  devices: devicesReducer,
  dashboard: dashboardReducer,
});
