import { Dispatch } from "react";

import { Device, DeviceCollection } from "../components/Devices/Device";
import { KeyCollection } from "../components/Keys/Key";
import {
  DEVICES_FETCHED,
  DEVICE_SHOW_CLOSE,
  DEVICE_SHOW_INIT,
  SHOW_DEVICE_ON_MAP_INIT,
  SHOW_DEVICE_ON_MAP_CLOSE,
  DEVICE_KEYS_BURN_INIT,
  DEVICE_KEYS_BURN_PARTIALLY_BURNED,
  DEVICE_KEYS_BURN_FINISHED,
  DEVICE_KEYS_BURN_CANCELLED,
} from "./types";
import { PaginationItemsMeta } from "../helpers/helpers";
import DataServiceReadService from "../providers/BlockchainNetworkProvider/DataServiceReadService";

export const devicesFetched = (
  devices: DeviceCollection,
  meta: PaginationItemsMeta
) => {
  return {
    type: DEVICES_FETCHED,
    payload: {
      devices,
      meta,
    },
  };
};

export const showDevice =
  (service: DataServiceReadService, address: string) =>
  (dispatch: Dispatch<any>) => {
    service
      .getDevice(address)
      .then((device: Device) => {
        dispatch({
          type: DEVICE_SHOW_INIT,
          payload: { device },
        });
      })
      .catch((e) => {
        console.log("Failed to fetch Device: ", e);
      });
  };

export const closeShowDevice = () => {
  return {
    type: DEVICE_SHOW_CLOSE,
  };
};

export const showDeviceMap =
  (service: DataServiceReadService, address: string) =>
  (dispatch: Dispatch<any>) => {
    service
      .getDevice(address)
      .then((device: Device) => {
        dispatch({
          type: SHOW_DEVICE_ON_MAP_INIT,
          payload: { device },
        });
      })
      .catch((e) => {
        console.log("Failed to fetch Devices: ", e);
      });
  };

export const closeShowDeviceMap = () => {
  return {
    type: SHOW_DEVICE_ON_MAP_CLOSE,
  };
};

export const deviceKeysBurnInit = (device: string, keys: KeyCollection) => {
  return {
    type: DEVICE_KEYS_BURN_INIT,
    payload: { device, keys },
  };
};

export const deviceKeysBurnCancel = () => {
  return {
    type: DEVICE_KEYS_BURN_CANCELLED,
  };
};

export const deviceKeysBurnFinished = (device: string, keys: KeyCollection) => {
  return {
    type: DEVICE_KEYS_BURN_FINISHED,
    payload: { device, keys },
  };
};

export const deviceKeysBurnPartiallyFinished = (
  device: string,
  keys: KeyCollection
) => {
  return {
    type: DEVICE_KEYS_BURN_PARTIALLY_BURNED,
    payload: { device, keys },
  };
};
