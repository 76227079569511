import Modal from "../../UI/Modal/Modal.component";
import { useSelector, useDispatch } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { ButtonGroup, Button, Typography } from "@mui/material";
import { verifierChanged } from "../../../actions/wavesKeeperActions";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";

const OrgVerifierModal = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgVerifier } = useSelector(
    (store: IRootReducer) => store.wavesKeeper
  );
  const { status } = useKeeper();

  let content = null;
  let footer = null;

  const tryAgainFooter = (t: TFunction) => {
    return (
      <ButtonGroup>
        <Button
          onClick={() => {
            dispatch(verifierChanged("waiting"));
          }}
        >
          {t("buttons.tryAgain")}
        </Button>
      </ButtonGroup>
    );
  };

  switch (orgVerifier) {
    case "waiting":
      content = (
        <Typography variant="h3">{t("orgVerifier.waiting.content")}</Typography>
      );
      break;
    case "scriptFetchFailed":
      content = (
        <Typography variant="h3">
          {t("orgVerifier.scriptFetchFailed.content")}
        </Typography>
      );
      footer = tryAgainFooter(t);
      break;
    case "pendingConfirm":
      content = (
        <Typography variant="h6">
          {t("orgVerifier.pendingConfirm.content")}
        </Typography>
      );
      footer = (
        <ButtonGroup>
          <Button
            color="success"
            onClick={() => {
              dispatch(verifierChanged("pendingTransaction"));
            }}
          >
            {t("buttons.confirm")}
          </Button>
          <Button
            color="error"
            onClick={() => {
              dispatch(verifierChanged("denied"));
            }}
          >
            {t("buttons.deny")}
          </Button>
        </ButtonGroup>
      );
      break;
    case "failedTransaction":
      content = (
        <Typography variant="h6">
          {t("orgVerifier.failedTransaction.content")}
        </Typography>
      );
      footer = tryAgainFooter(t);
      break;
    case "pendingTransaction":
      content = (
        <Typography variant="h6">
          {t("orgVerifier.pendingTransaction.content")}
        </Typography>
      );
      break;
    case "noAccount":
      if (status !== "connected") break;
      content = (
        <Typography variant="h6">
          {t("orgVerifier.noAccount.content")}
        </Typography>
      );
      break;
    case "denied":
      content = (
        <Typography variant="h6">{t("orgVerifier.denied.content")}</Typography>
      );
      footer = tryAgainFooter(t);
      break;
  }

  return (
    <Modal closable={false} show={content !== null} footer={footer}>
      <div style={{ textAlign: "center" }}>{content}</div>
    </Modal>
  );
};

export default OrgVerifierModal;
