import React from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";

interface ListItemProps {
  icon: JSX.Element;
  text: string;
  secondaryText?: string;
  href?: string;
  onClick?: () => void;
  button?: boolean;
}

const ListItemLink = (props: ListItemProps) => {
  const { icon, text, secondaryText, href } = props;
  const { pathname } = useLocation();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return (
          <RouterLink to={href as string} {...itemProps} role={undefined} />
        );
      }),
    [href]
  );

  return (
    <ListItemButton component={renderLink} selected={pathname === href}>
      <Tooltip title={text}>
        <ListItemIcon>{icon}</ListItemIcon>
      </Tooltip>

      <ListItemText primary={text} secondary={secondaryText} />
    </ListItemButton>
  );
};

const ListItem: React.FC<ListItemProps> = (props: ListItemProps) => {
  const { icon, text, secondaryText, href } = props;

  if (href) {
    return <ListItemLink {...props} />;
  }

  return (
    <ListItemButton>
      <Tooltip title={text}>
        <ListItemIcon>{icon}</ListItemIcon>
      </Tooltip>
      <ListItemText primary={text} secondary={secondaryText} />
    </ListItemButton>
  );
};

export default ListItem;
