import {
  Token,
  TokenEntry,
  TokenCollection,
  TokenEntryCollection,
} from "./Token";

export const tokensToCollection = (tokens: Token[]): TokenCollection => {
  const collection: TokenCollection = {};

  tokens.forEach((token: Token) => {
    collection[token.assetId] = token;
  });

  return collection;
};

export const tokenEntriesToCollection = (
  tokens: TokenEntry[]
): TokenEntryCollection => {
  const collection: TokenEntryCollection = {};

  tokens.forEach((entry: TokenEntry) => {
    collection[entry.assetId] = entry;
  });

  return collection;
};
