import { IconButton, Tooltip, ButtonGroup } from "@mui/material";
import { MoveUp, MoveDown, Delete } from "@mui/icons-material";
import { AnyAction } from "redux";
import { SnackbarKey, SnackbarMessage, OptionsObject } from "notistack";
import { Dispatch } from "react";
import { TFunction } from "i18next";
import { Token, TokenEntry } from "../Token";
import {
  tokenEntryDeleted,
  tokenEntryActivated,
  tokenEntryDeactivated,
} from "../../../actions/tokensActions";
import KeeperWriteService from "../../../providers/KeeperProvider/KeeperWriteService";

const TokenEntryRow = (props: {
  tokenEntry: TokenEntry;
  dispatch: Dispatch<any>;
  t: TFunction;
  token?: Token;
  writeService: KeeperWriteService | null;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
}) => {
  const activateTransactionData = (
    active: boolean
  ): WavesKeeper.TDataTxData => {
    return {
      type: 12,
      data: {
        data: [
          {
            type: "string",
            key: `token_${props.tokenEntry.assetId}`,
            value: active ? "active" : "inactive",
          },
        ],
        fee: {
          tokens: 0.009,
          assetId: "WAVES",
        },
      },
    };
  };

  const deleteEntryTransactionData = (): any => {
    // I have to do this because waveskeeper-types
    // throws an error when trying to delete entries
    const deleteEntryTxData: any = {
      key: `token_${props.tokenEntry.assetId}`,
      value: null,
    };

    return {
      type: 12,
      data: {
        version: 2,
        data: [deleteEntryTxData],
        fee: {
          tokens: 0.009,
          assetId: "WAVES",
        },
      },
    };
  };

  const broadcastTransaction = (
    txData: any,
    dispatchAction: AnyAction,
    successMessage?: string
  ) => {
    if (!props.writeService) return;
    props.writeService
      .publishTransaction(txData)
      .then((res) => {
        props.dispatch(dispatchAction);
        props.enqueueSnackbar(
          successMessage || props.t("messages.tokens.tokenUpdated"),
          { variant: "success" }
        );
      })
      .catch((e) => {
        props.enqueueSnackbar(
          props.t("messages.keeper.broadcastFailed", {
            message: e.message,
          }),
          { variant: "error" }
        );
      });
  };

  const deactivateToken = () => {
    const txData = activateTransactionData(false);
    const action = tokenEntryDeactivated(props.tokenEntry);
    broadcastTransaction(
      txData,
      action,
      props.t("messages.tokens.tokenDeactivated")
    );
  };

  const activateToken = () => {
    const txData = activateTransactionData(true);
    const action = tokenEntryActivated(props.tokenEntry);
    broadcastTransaction(
      txData,
      action,
      props.t("messages.tokens.tokenActivated")
    );
  };

  const deleteEntry = () => {
    const txData = deleteEntryTransactionData();
    const action = tokenEntryDeleted(props.tokenEntry);
    broadcastTransaction(
      txData,
      action,
      props.t("messages.tokenEntries.entryDeleted")
    );
  };

  const renderActions = (): JSX.Element => {
    const buttons: JSX.Element[] = [];

    if (props.tokenEntry.active) {
      buttons.push(
        // <Button
        //   key="deactivate"
        //   color="warning"
        //   onClick={() => deactivateToken()}
        // >
        //   {props.t("buttons.tokens.deactivate")}
        // </Button>

        <Tooltip title={props.t("buttons.tokens.deactivate") || "Deactivate"}>
          <IconButton
            key="deactivate"
            color="warning"
            onClick={() => deactivateToken()}
          >
            <MoveDown />
          </IconButton>
        </Tooltip>
      );
    } else {
      buttons.push(
        <Tooltip title={props.t("buttons.tokens.activate") || "Activate"}>
          <IconButton
            key="activate"
            color="success"
            onClick={() => activateToken()}
          >
            <MoveUp />
          </IconButton>
        </Tooltip>
      );
    }

    buttons.push(
      <Tooltip title={props.t("buttons.tokens.remove") || "Activate"}>
        <IconButton key="remove" color="error" onClick={() => deleteEntry()}>
          <Delete />
        </IconButton>
      </Tooltip>
    );

    return <ButtonGroup size="small">{buttons}</ButtonGroup>;
  };

  return {
    assetId: props.tokenEntry.assetId,
    name: props.token ? props.token.issueTransaction.name : "",
    actions: renderActions(),
  };
};

export default TokenEntryRow;
