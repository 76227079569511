import { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { User } from "../../User";
import { userUpdated } from "../../../../actions/usersActions";
import { IRootReducer } from "../../../../reducers";
import QrScannerModal from "../../../Modals/QrScannerModal/QrScannerModal.component";
import { useKeeper } from "../../../../providers/KeeperProvider/KeeperProvider";
import { Box, Button, IconButton, TextField } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import {
  buildValidationSchema,
  buildEditValidationSchema,
} from "./ValidationSchema";

const AddUserForm = (props: { handleClose: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, user } = useSelector((state: IRootReducer) => state.users);
  const [scannerEnabled, setScannerEnabled] = useState<boolean>(false);
  const { writeService, encryptor, publicState } = useKeeper();
  const { enqueueSnackbar } = useSnackbar();

  const action = user && user.address.length > 0 ? "edit" : "add";

  const validationSchema =
    action === "edit"
      ? buildEditValidationSchema(t)
      : buildValidationSchema(t, data);

  const onSubmit = async (values: User, formikProps: FormikHelpers<User>) => {
    if (!writeService) return;
    if (publicState?.locked) {
      enqueueSnackbar(t("messages.keeper.locked"), { variant: "error" });
      return;
    }
    formikProps.setSubmitting(true);

    // Default value of mobileId equals '?'
    if (!values.mobileId || values.mobileId === "") {
      values.mobileId = "?";
    }

    // Encrypt the note
    if (encryptor && values.note && values.note.length > 0) {
      values.encNote = await encryptor.encrypt(values.note);
    }

    writeService
      .addUser(values)
      .then((res: User) => {
        enqueueSnackbar(
          t(`messages.users.user${action === "add" ? "Added" : "Updated"}`),
          { variant: "success" }
        );
        dispatch(userUpdated(res));
        props.handleClose();
      })
      .catch((e: any) => {
        enqueueSnackbar(
          t("messages.keeper.broadcastFailed", {
            message: e.message,
          }),
          { variant: "error" }
        );
      });

    formikProps.setSubmitting(false);
  };

  const onQrScanCompleted = (result: string, setFieldValue: any) => {
    setFieldValue("address", result);
    enqueueSnackbar(t("messages.qrScanner.completed"), { variant: "info" });
    setScannerEnabled(false);
  };

  const onQrScanError = (error: Error) => {
    enqueueSnackbar(
      t("messages.qrScanner.failed", { message: error.message }),
      { variant: "error" }
    );
    setScannerEnabled(false);
  };

  const onQrScanButtonClick = () => {
    setScannerEnabled(!scannerEnabled);
  };

  const buildScanner = (setFieldValue: any) => {
    if (!scannerEnabled) return <></>;

    return (
      <QrScannerModal
        onScanned={(result) => onQrScanCompleted(result, setFieldValue)}
        onError={onQrScanError}
        onClose={onQrScanButtonClick}
      />
    );
  };

  return (
    <Formik
      initialValues={user || { address: "", mobileId: "", note: "" }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        submitForm,
        dirty,
        isValid,
        setFieldValue,
      }) => (
        <>
          {action === "add" && (
            <Box sx={{ display: "flex" }}>
              <TextField
                fullWidth
                label={t("forms.user.fields.address.label")}
                placeholder={t("forms.user.fields.address.placeholder")}
                name="address"
                type="text"
                value={values.address}
                onChange={handleChange}
                error={!!errors.address}
                helperText={errors.address && errors.address}
                sx={{ marginTop: "5px", display: "flex" }}
              ></TextField>
              <IconButton
                onClick={onQrScanButtonClick}
                sx={{ alignSelf: "flex-start", marginTop: "13px" }}
              >
                <QrCodeScannerIcon />
              </IconButton>
              {buildScanner(setFieldValue)}
            </Box>
          )}

          <TextField
            label={t("forms.user.fields.mobileId.label")}
            placeholder={t("forms.user.fields.mobileId.placeholder")}
            name="mobileId"
            type="string"
            onChange={handleChange}
            value={values.mobileId}
            fullWidth
            sx={{ marginTop: "15px" }}
            error={!!errors.mobileId}
            helperText={errors.mobileId && errors.mobileId}
          />

          <TextField
            label={t("forms.user.fields.note.label")}
            placeholder={t("forms.user.fields.note.placeholder")}
            rows={3}
            minRows={3}
            name="note"
            type="string"
            onChange={handleChange}
            value={values.note}
            fullWidth
            sx={{ marginTop: "15px" }}
            error={!!errors.note}
            helperText={errors.note && errors.note}
          />

          <Button
            type="submit"
            disabled={!dirty || !isValid || isSubmitting}
            onClick={submitForm}
            onSubmit={(e: React.FormEvent<HTMLButtonElement>) =>
              e.preventDefault()
            }
            variant="outlined"
            sx={{ marginTop: "40px" }}
          >
            {t(`forms.${action}User.buttons.submit`)}
          </Button>
          <Button
            onClick={props.handleClose}
            variant="outlined"
            sx={{ marginTop: "40px" }}
          >
            {t(`buttons.close`)}
          </Button>
        </>
      )}
    </Formik>
  );
};

export default AddUserForm;
