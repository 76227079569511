import React from "react";
import { Chip, CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";

import useDAppFatherVerification from "../../../hooks/useDAppFatherVerification";

const VerifiedBadge: React.FC = (props: any) => {
  const { t } = useTranslation();

  const { verified, loading } = useDAppFatherVerification();

  if (loading) {
    return <CircularProgress />;
  } else if (verified) {
    return <></>;
  } else {
    return (
      <Chip
        label={t("interface.header.verifiedBadge.notVerified")}
        color="error"
        variant="filled"
        size="small"
      />
    );
  }
};

export default VerifiedBadge;
