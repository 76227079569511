import * as yup from "yup";
import { base58Decode } from "@waves/ts-lib-crypto";
import { AnyObject, Maybe } from "yup/lib/types";
import { BLOCKCHAIN_ADDRESS_REGEXP } from "./regexp";

const chainIdFromAddress = (address: string): string => {
  const bytes = Buffer.from(base58Decode(address));
  return String.fromCharCode(bytes[1]);
};

yup.addMethod<yup.StringSchema>(
  yup.string,
  "blockchainAddress",
  function (errorMessage) {
    return this.test("test-blockchain-address", errorMessage, function (value) {
      const { path, createError } = this;

      if (!value || value === "") {
        return false;
      }

      const matches = value.match(BLOCKCHAIN_ADDRESS_REGEXP);

      return (
        (matches && matches.length > 0) ||
        createError({ path, message: errorMessage })
      );
    });
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "addressChainId",
  function (expectedChainId, errorMessage) {
    return this.test("test-blockchain-address", errorMessage, function (value) {
      const { path, createError } = this;

      if (!value || value === "") {
        return false;
      }

      const addressChainId = chainIdFromAddress(value);

      return (
        addressChainId === expectedChainId ||
        createError({ path, message: errorMessage })
      );
    });
  }
);

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    // emptyAsUndefined(): StringSchema<TType, TContext>;
    blockchainAddress(msg: string): StringSchema<TType, TContext>;
    addressChainId(
      expectedChainId: string | undefined,
      msg: string
    ): StringSchema<TType, TContext>;
  }
}

export default yup;
