import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DeviceDetails } from "../Device";
import { render } from "./attributesRenderer";

const blacklist = ["physicalAddress"];

const ShowDeviceDetails = (props: { details?: DeviceDetails }) => {
  const { t } = useTranslation();
  const { details } = props;

  if (!details) return null;

  return (
    <Grid container spacing={1}>
      {render(details, t, "device.model.details", blacklist)}
    </Grid>
  );
};

export default ShowDeviceDetails;
