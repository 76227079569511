import {
  DASHBOARD_DEVICES_MAP_MARKER_CLICKED,
  DASHBOARD_DEVICES_MAP_INFOBOX_CLOSED,
} from "../types";

export const markerClicked = (address: string) => {
  return {
    type: DASHBOARD_DEVICES_MAP_MARKER_CLICKED,
    payload: { address },
  };
};

export const infoBoxClosed = () => {
  return {
    type: DASHBOARD_DEVICES_MAP_INFOBOX_CLOSED,
  };
};
