import { ButtonGroup, IconButton, Tooltip } from "@mui/material";
import { Send, Whatshot, Toll, MoveUp, MoveDown } from "@mui/icons-material";
import { SnackbarMessage, OptionsObject, SnackbarKey } from "notistack";
import { Dispatch } from "react";
import { TFunction } from "i18next";
import { Token, TokenEntryCollection } from "../Token";
import ActiveChip from "../../UI/Chips/ActiveChip";
import {
  tokenActivated,
  tokenDeactivated,
  tokenBurned,
  sendTokenInit,
  reissueTokenInit,
} from "../../../actions/tokensActions";
import {
  activateTransactionData,
  burnTransactionData,
} from "../../../providers/KeeperProvider/helpers/transactions";
import KeeperWriteService from "../../../providers/KeeperProvider/KeeperWriteService";

const TokenRow = (props: {
  token: Token;
  tokenEntries: TokenEntryCollection;
  dispatch: Dispatch<any>;
  t: TFunction;
  writeService: KeeperWriteService | null;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
}) => {
  const broadcastTransaction = (
    txData: any,
    dispatchData: any,
    successMessage?: string
  ) => {
    if (!props.writeService) return;
    props.writeService
      .publishTransaction(txData)
      .then(() => {
        props.enqueueSnackbar(
          successMessage || props.t("messages.tokens.tokenUpdated"),
          { variant: "success" }
        );
        props.dispatch(dispatchData);
      })
      .catch((e) => {
        props.enqueueSnackbar(
          props.t("messages.keeper.broadcastFailed", {
            message: e.message,
          }),
          { variant: "error" }
        );
      });
  };

  const activateToken = (token: Token) => {
    const txData = activateTransactionData(token.assetId, true);
    const dispatchData = tokenActivated(token);
    broadcastTransaction(
      txData,
      dispatchData,
      props.t("messages.tokens.tokenActivated")
    );
  };

  const deactivateToken = (token: Token) => {
    const txData = activateTransactionData(token.assetId, false);
    const dispatchData = tokenDeactivated(token);
    broadcastTransaction(
      txData,
      dispatchData,
      props.t("messages.tokens.tokenDeactivated")
    );
  };

  const burnToken = (token: Token) => {
    const txData = burnTransactionData(token.assetId, token.balance);
    const dispatchData = tokenBurned(token);
    broadcastTransaction(
      txData,
      dispatchData,
      props.t("messages.tokens.tokenBurned")
    );
  };

  const reissueToken = (token: Token) => {
    props.dispatch(reissueTokenInit(token));
  };

  const handleSendButton = (token: Token) => {
    props.dispatch(sendTokenInit(token));
  };

  const isTokenActive = (token: Token): boolean => {
    return !!props.tokenEntries[token.assetId]?.active;
  };

  const renderActions = (token: Token): JSX.Element => {
    const buttons: JSX.Element[] = [];

    buttons.push(
      <Tooltip title={props.t("buttons.tokens.send") || "Send"}>
        <IconButton
          key="send"
          color="primary"
          disabled={token.balance === 0}
          onClick={() => handleSendButton(token)}
        >
          <Send />
        </IconButton>
      </Tooltip>
    );

    if (isTokenActive(token)) {
      buttons.push(
        <Tooltip title={props.t("buttons.tokens.deactivate") || "Deactivate"}>
          <IconButton
            key="deactivate"
            color="warning"
            onClick={() => deactivateToken(token)}
          >
            <MoveDown />
          </IconButton>
        </Tooltip>
      );
    } else {
      buttons.push(
        <Tooltip title={props.t("buttons.tokens.activate") || "Activate"}>
          <IconButton
            key="activate"
            color="success"
            onClick={() => activateToken(token)}
          >
            <MoveUp />
          </IconButton>
        </Tooltip>
      );
    }

    if (token.reissuable) {
      buttons.push(
        <Tooltip title={props.t("buttons.tokens.reissue") || "Reissue"}>
          <IconButton
            key="reissue"
            color="info"
            onClick={() => reissueToken(token)}
          >
            <Toll />
          </IconButton>
        </Tooltip>
      );
    }

    buttons.push(
      <Tooltip title={props.t("buttons.tokens.burn") || "Burn"}>
        <IconButton
          key="burn"
          color="error"
          disabled={token.balance === 0}
          onClick={() => burnToken(token)}
        >
          <Whatshot />
        </IconButton>
      </Tooltip>
    );

    return <ButtonGroup size="small">{buttons}</ButtonGroup>;
  };

  return {
    assetId: props.token.assetId,
    name: props.token.issueTransaction.name,
    description: props.token.issueTransaction.description,
    balance: props.token.balance,
    active: <ActiveChip active={isTokenActive(props.token)} />,
    actions: renderActions(props.token),
  };
};

export default TokenRow;
