import * as Yup from "yup";
import { TFunction } from "i18next";

const buildValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(
        t("forms.validation.required", {
          field: t("forms.createToken.fields.name.label"),
        })
      )
      .min(
        4,
        t("forms.validation.string.min", {
          field: t("forms.createToken.fields.name.label"),
          min: 4,
        })
      )
      .max(
        16,
        t("forms.validation.string.max", {
          field: t("forms.createToken.fields.name.label"),
          max: 16,
        })
      ),
    description: Yup.string()
      .optional()
      .min(
        0,
        t("forms.validation.string.min", {
          field: t("forms.createToken.fields.description.label"),
          min: 0,
        })
      )
      .max(
        1000,
        t("forms.validation.string.max", {
          field: t("forms.createToken.fields.description.label"),
          max: 1000,
        })
      ),
    quantity: Yup.number()
      .required(
        t("forms.validation.required", {
          field: t("forms.createToken.fields.quantity.label"),
        })
      )
      .positive(
        t("forms.validation.number.positive", {
          field: t("forms.createToken.fields.quantity.label"),
        })
      ),
  });
};

export default buildValidationSchema;
