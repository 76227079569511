import { getIn } from "formik";
import { FormHelperText } from "@mui/material";

const RecipientErrorMessage = (props: {
  attribute: string;
  index: number;
  errors: any;
}) => {
  const error = getIn(
    props.errors,
    `recipients[${props.index}].${props.attribute}`
  );
  return error ? <FormHelperText error>{error}</FormHelperText> : null;
};

export default RecipientErrorMessage;
