export const isObjectEmpty = (obj: object) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

// Helper method to parse current search value
//
export const searchToSearchQuery = (search: string | undefined) => {
  if (!search || search.length >= 3) return search;

  return undefined;
};

export interface PaginationItemsMeta {
  total: number;
}
