import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";

import Modal from "../../UI/Modal/Modal.component";

interface QrScannerModalProps {
  onClose: () => void;
  onScanned: (result: string) => void;
  onError: (error: Error) => void;
  header?: React.ReactNode;
}

const QrScannerModal: React.FC<QrScannerModalProps> = (
  props: QrScannerModalProps
) => {
  const { onScanned, onError, onClose, header } = props;
  const { t } = useTranslation();

  const footer = (
    <>
      <Button variant="contained" color="error" onClick={onClose}>
        {t("buttons.close")}
      </Button>
    </>
  );

  return (
    <Modal closable show={true} handleClose={onClose} footer={footer}>
      <Grid container spacing={3}>
        {header && (
          <Grid item xs={12}>
            {header}
          </Grid>
        )}
        <Grid item xs={12}>
          <QrReader
            onScan={(result) => {
              if (result) {
                onScanned(result);
              }
            }}
            onError={onError}
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default QrScannerModal;
