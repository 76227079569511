import { AnyAction } from "redux";
import { VerifierStatus } from "../hooks/useOrgVerifier";

import { ORG_VERIFIER_STATUS_CHANGED } from "../actions/types";

export interface IWavesKeeperReducer {
  orgVerifier: VerifierStatus;
}

const initialValue: IWavesKeeperReducer = {
  orgVerifier: "init",
};

const wavesKeeperReducer = (state = initialValue, action: AnyAction) => {
  switch (action.type) {
    case ORG_VERIFIER_STATUS_CHANGED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default wavesKeeperReducer;
