import React from "react";
import { Toolbar, Drawer, Divider, List, Box } from "@mui/material";

import { DeviceHub, Person, Dashboard, Key, Toll } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import ListItem from "./ListItem/ListItem.component";
import LanguageSelector from "./LanguageSelector.component";
import NetworkSettings from "../../UI/NetworkSettings/NetworkSettings.component";
import DarkModeToggle from "./DarkModeToggle.component";

const Sidebar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: "250px",
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: "250px", boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <Divider />
        <List>
          <ListItem
            button
            icon={<Dashboard />}
            text={t("interface.sidebar.dashboard") ?? "Dashboard"}
            href="/"
          />
          <ListItem
            button
            icon={<Toll />}
            text={t("interface.sidebar.tokens") ?? "Tokens"}
            href="/tokens"
          />

          <ListItem
            button
            icon={<Person />}
            text={t("interface.sidebar.users") ?? "Users"}
            href="/users"
          />

          <ListItem
            button
            icon={<Key />}
            text={t("interface.sidebar.keys") ?? "Keys"}
            href="/keys"
          />

          <ListItem
            button
            icon={<DeviceHub />}
            text={t("interface.sidebar.devices") ?? "Devices"}
            href="/devices"
          />
        </List>
        <Divider />
        <NetworkSettings />
        <LanguageSelector />
        <DarkModeToggle />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
