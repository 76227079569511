import { useState, useMemo, useEffect } from "react";
import { Button, ButtonGroup } from "@mui/material";
import { GroupAdd } from "@mui/icons-material";
import { FormikHelpers, FieldArray, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Token } from "../../Token";
import { IRootReducer } from "../../../../reducers";
import buildValidationSchema from "./validationSchema";
import buildTxData from "./transactionBuilder";
import { tokensSent } from "../../../../actions/tokensActions";

import QrScannerModal from "../../../Modals/QrScannerModal/QrScannerModal.component";

import RecipientRow from "./Components/RecipientRow.component";
import { useKeeper } from "../../../../providers/KeeperProvider/KeeperProvider";

export interface Recipient {
  address: string;
  amount: number;
}

export interface ISendTokenForm {
  assetId: string;
  recipients: Recipient[];
}

function SendTokenForm(props: {
  handleSubmit?: () => void;
  handleClose?: () => void;
  onAmountChanged?: (totalTokens: number) => void;
  availableTokensCount?: number;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { writeService, publicState } = useKeeper();
  const sendToken = useSelector(
    (state: IRootReducer) => state.tokens.sendToken as Token
  );
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = useMemo(() => {
    return buildValidationSchema(sendToken.balance, t, publicState);
  }, [sendToken.balance, t, publicState]);

  const [scannerIndex, setScannerIndex] = useState<number | undefined>();

  function onSubmit(
    values: ISendTokenForm,
    formikProps: FormikHelpers<ISendTokenForm>
  ) {
    if (!writeService) return;
    formikProps.setSubmitting(true);
    writeService
      .publishTransaction(buildTxData(values))
      .then((res: string) => {
        enqueueSnackbar(t("messages.forms.sendToken.tokensSent"), {
          variant: "success",
        });
        if (props.handleSubmit) {
          props.handleSubmit();
        }
        dispatch(tokensSent(JSON.parse(res)));
      })
      .catch((e) => {
        enqueueSnackbar(
          t("messages.keeper.broadcastFailed", {
            message: e.message,
          }),
          { variant: "error" }
        );
      });
    formikProps.setSubmitting(false);
  }

  const onQrScanButtonClicked = (index: number) => {
    setScannerIndex(index);
  };

  const onQrCodeScanned = (setFieldValue: any, result: string) => {
    setFieldValue(`recipients[${scannerIndex}].address`, result);
    enqueueSnackbar(t("messages.qrScanner.completed"), { variant: "info" });
    setScannerIndex(undefined);
  };

  const onQrCodeError = (error: Error) => {
    enqueueSnackbar(
      t("messages.qrScanner.failed", { message: error.message }),
      { variant: "error" }
    );
    setScannerIndex(undefined);
  };

  const buildScanner = (setFieldValue: any) => {
    if (scannerIndex === undefined) {
      return <></>;
    }

    return (
      <QrScannerModal
        onClose={() => setScannerIndex(undefined)}
        onScanned={(result) => onQrCodeScanned(setFieldValue, result)}
        onError={onQrCodeError}
        header={<h1>{t("common.scanQrCode")}</h1>}
      />
    );
  };

  const formik = useFormik({
    initialValues: {
      assetId: sendToken.assetId,
      recipients: [{ address: "", amount: 1 }],
    },
    onSubmit,
    validationSchema,
  });

  const currentAmount = useMemo(() => {
    if (formik.values.recipients.length === 0) return 0;

    return formik.values.recipients.reduce((a, b) => {
      return isNaN(b.amount) ? a : a + b.amount;
    }, 0);
  }, [formik.values.recipients]);

  useEffect(() => {
    if (!props.onAmountChanged) return;

    props.onAmountChanged(currentAmount);
  }, [props, currentAmount]);

  const RecipientsArrayErrors = () =>
    typeof formik.errors.recipients === "string" ? (
      <div style={{ color: "red" }}>{formik.errors.recipients}</div>
    ) : null;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <FieldArray
          name="recipients"
          render={(arrayHelpers) => {
            return (
              <div>
                {formik.values.recipients ? (
                  <div>
                    {formik.values.recipients.map((recipient, index) => (
                      <RecipientRow
                        key={`recipient_${index}`}
                        index={index}
                        recipient={recipient}
                        formik={formik}
                        onQrScanButtonClicked={onQrScanButtonClicked}
                        arrayHelpers={arrayHelpers}
                      />
                    ))}

                    <Button
                      variant="contained"
                      color="success"
                      disabled={
                        formik.values.recipients.length > 100 ||
                        props.availableTokensCount === 0
                      }
                      onClick={() =>
                        arrayHelpers.push({ address: "", amount: 1 })
                      }
                      startIcon={<GroupAdd />}
                    >
                      {t("forms.sendToken.buttons.addRecipient")}
                    </Button>
                  </div>
                ) : null}
              </div>
            );
          }}
        />
      </FormikProvider>

      <br />
      <RecipientsArrayErrors />
      <br />
      <ButtonGroup>
        <Button
          type="submit"
          variant="contained"
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
        >
          {t("forms.sendToken.buttons.submit")}
        </Button>

        <Button variant="contained" color="error" onClick={props.handleClose}>
          {t("forms.sendToken.buttons.close")}
        </Button>
      </ButtonGroup>

      {buildScanner(formik.setFieldValue)}
    </form>
  );
}

export default SendTokenForm;
