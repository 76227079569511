import { Key } from "../../Key";
import { Tooltip } from "@mui/material";
import { useBlockchainNetwork } from "../../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";

const DeviceFormatter = (props: { row: Key }): JSX.Element => {
  const { network } = useBlockchainNetwork();
  const explorerUrl = network?.explorer.url;

  const renderExplorerUrl = () => {
    if (!explorerUrl) {
      return null;
    }

    return `${explorerUrl}/address/${props.row.device}`;
  };

  const url = renderExplorerUrl();

  return (
    <Tooltip title={props.row.device}>
      {url ? (
        <a href={url} target="_blank" rel="noreferrer">
          {props.row.deviceName}
        </a>
      ) : (
        <span>{props.row.deviceName}</span>
      )}
    </Tooltip>
  );
};

export default DeviceFormatter;
