import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";

interface IActiveChip {
  active: boolean;
}

const ActiveChip: React.FC<IActiveChip> = ({ active }) => {
  const { t } = useTranslation();

  const label = t(`common.${active ? "active" : "inactive"}`);
  const color = active ? "success" : "error";

  return <Chip label={label} color={color} />;
};

export default ActiveChip;
