/// <reference types="google.maps" />

import { Dispatch, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { Clusterer } from "@react-google-maps/marker-clusterer";
import { useTheme } from "@mui/material";

import { Device } from "../../../Devices/Device";
import DeviceInfoWindow from "./DeviceInfoWindow/DeviceInfoWindow.component";
import { showDevice } from "../../../../actions/devicesActions";
import { IRootReducer } from "../../../../reducers";
import {
  markerClicked,
  infoBoxClosed,
} from "../../../../actions/dashboard/devicesMapActions";
import { useBlockchainNetwork } from "../../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import pickExpirationColor from "../../../../helpers/keyExpirationThreshold";

const MARKER_SVG =
  "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";

export interface DeviceMarkerProps {
  device: Device;
  clusterer: Clusterer;
  dispatch: Dispatch<any>;
  onClick?: (e: google.maps.MapMouseEvent, device: Device) => void;
}

const DeviceMarker = (props: DeviceMarkerProps) => {
  const { read } = useBlockchainNetwork();
  const { activeMarker } = useSelector(
    (state: IRootReducer) => state.dashboard.devicesMap
  );
  const theme = useTheme();

  const position = useMemo(() => {
    return {
      lat: props.device.lat as number,
      lng: props.device.lng as number,
    };
  }, [props.device]);

  const onInfoWindowClose = useCallback(() => {
    props.dispatch(infoBoxClosed());
  }, [props]);

  const onMarkerClick = (e: google.maps.MapMouseEvent) => {
    props.dispatch(markerClicked(props.device.address));
    if (!props.onClick) return;

    props.onClick(e, props.device);
  };

  const onDetailsButtonClicked = useCallback(() => {
    if (!read) return;
    props.dispatch(showDevice(read, props.device.address));
  }, [read, props]);

  const infoWindow = useCallback(() => {
    return (
      <InfoWindow position={position} onCloseClick={onInfoWindowClose}>
        <DeviceInfoWindow
          device={props.device}
          onDetailsClicked={onDetailsButtonClicked}
        />
      </InfoWindow>
    );
  }, [props.device, onDetailsButtonClicked, onInfoWindowClose, position]);

  const icon = useMemo(() => {
    const { key } = props.device;

    let fillColor: string = theme.palette.primary.light;

    if (key) {
      fillColor = pickExpirationColor(key.validTo, theme);
    }

    return {
      path: MARKER_SVG,
      scale: 1.5,
      fillColor,
      fillOpacity: 1,
      anchor: { x: 12, y: 32, equals: (other: any) => false },
    };
  }, [props.device, theme]);

  const marker = (
    <Marker
      icon={icon}
      position={position}
      clusterer={props.clusterer}
      onClick={onMarkerClick}
    >
      {activeMarker === props.device.address && infoWindow()}
    </Marker>
  );

  return marker;
};

export default DeviceMarker;
