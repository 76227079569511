import Modal from "../../UI/Modal/Modal.component";
import NetworkSettingsForm from "../../Forms/NetworkSettingsForm/NetworkSettingsForm.component";
import { BlockchainNetworkConfig } from "../../../providers/BlockchainNetworkProvider";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface NetworkSettingsModalProps {
  show: boolean;
  handleClose: () => any;
  handleSubmit: (form: BlockchainNetworkConfig) => Promise<any>;
}

const NetworkSettingsModal: React.FC<NetworkSettingsModalProps> = ({
  show,
  handleClose,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} closable handleClose={handleClose}>
      <Typography variant="h3" sx={{ mb: 3 }}>
        {t("forms.networkSettings.header")}
      </Typography>
      <NetworkSettingsForm
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default NetworkSettingsModal;
