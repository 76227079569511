import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { Token } from "../Token";
import { tokensToCollection } from "../helpers";
import {
  tokensFetched,
  tokenEntriesFetched,
} from "../../../actions/tokensActions";
import { IRootReducer } from "../../../reducers";
import TokenRow from "./TokenRow.component";
import useStatusVerifier from "../../../hooks/useStatusVerifier";
import usePublicAddress from "../../../hooks/usePublicAddress";
import { useTranslation } from "react-i18next";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";
import { Column } from "react-table";
import { CellArgument } from "../../UI/MUITable/ITable";
import CustomTable from "../../UI/MUITable/CustomTable";

const TokensTable = (props: any) => {
  const { t } = useTranslation();

  const { tokens, tokenEntries } = useSelector(
    (state: IRootReducer) => state.tokens
  );
  const publicAddress = usePublicAddress();
  const orgVerified = useStatusVerifier();
  const dispatch = useDispatch();
  const { readService, writeService } = useKeeper();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!publicAddress || !readService) return;

    readService.fetchAllTokens(publicAddress).then((activeTokens) => {
      dispatch(tokenEntriesFetched(activeTokens));
    });

    readService.fetchAssets(publicAddress).then((tokens: Token[]) => {
      const collection = tokensToCollection(tokens);
      dispatch(tokensFetched(collection));
    });
  }, [orgVerified, publicAddress, dispatch, readService]);

  const renderedTokens = Object.values(tokens).map((token) => {
    return TokenRow({
      token,
      tokenEntries,
      dispatch,
      t,
      writeService,
      enqueueSnackbar,
    });
  });

  type ColumnItem = typeof renderedTokens[0];

  const columns: Column<ColumnItem>[] = [
    {
      accessor: "name",
      Header: t("tables.tokens.columns.name") ?? "name",
    },
    {
      accessor: "description",
      Header: t("tables.tokens.columns.description") ?? "description",
    },
    {
      accessor: "balance",
      Header: t("tables.tokens.columns.balance") ?? "balance",
    },
    {
      accessor: "active",
      Header: t("tables.tokens.columns.active") ?? "active",
    },
    {
      id: "actions",
      Header: t("tables.tokens.columns.actions") ?? "actions",
      Cell: (arg: CellArgument<ColumnItem>) => arg.row.original.actions,
    },
  ];

  return (
    <CustomTable
      mode="local"
      data={renderedTokens}
      columns={columns}
      options={{
        addItemButton: true,
        addItemButtonLabel: t("buttons.tokens.create"),
        onAddItem: props.onAddItem,
      }}
    />
  );
};

export default TokensTable;
