import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Device } from "../../../../Devices/Device";
import KeyExpirationBadge from "../../../../UI/KeyExpirationBadge/KeyExpirationBadge.component";

interface DeviceInfoWindowProps {
  device: Device;
  onDetailsClicked: () => any;
}

const DeviceInfoWindow: React.FC<DeviceInfoWindowProps> = ({
  device,
  onDetailsClicked,
}) => {
  const { t } = useTranslation();
  const { key } = device;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6" color="black" sx={{ mb: 1 }}>
          {device.name}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          {key && <KeyExpirationBadge deviceKey={key} />}
        </Grid>
      </Grid>

      <Grid item xs={2} justifyContent="flex-end">
        <Button variant="outlined" color="primary" onClick={onDetailsClicked}>
          {t("dashboard.devicesMap.detailsButton")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DeviceInfoWindow;
