import React from "react";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  Theme,
} from "@mui/material/styles";

interface buildThemeProps {
  darkMode?: boolean;
}

interface LayoutContextProps {
  darkMode: boolean;
  sidebarActive: boolean;
  theme: Theme;
  layoutActions: {
    switchDarkMode: () => void;
    switchSidebar: () => void;
  };
}

const buildTheme = (props: buildThemeProps) => {
  return createTheme({
    palette: {
      mode: props.darkMode ? "dark" : "light",
      primary: {
        main: props.darkMode ? "#3495FF" : "#5C44EC",
      },
      secondary: {
        main: props.darkMode ? "#6a34ff" : "#b144ec",
      },
      background: {
        default: props.darkMode ? "#383838" : "#fafafa",
        paper: props.darkMode ? "#1e1e1e" : "#fff",
      },
    },
    typography: {
      fontFamily: "Inter",
    },
  });
};

const isDarkModeEnabled: () => boolean = () => {
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};

export const LayoutContext = React.createContext<LayoutContextProps>(
  null as any
);

export const LayoutProvider: React.FC = (props) => {
  const [darkMode, setDarkMode] = React.useState<boolean>(isDarkModeEnabled());
  const [sidebarActive, setSidebarActive] = React.useState<boolean>(false);

  const switchDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const switchSidebar = () => {
    setSidebarActive(!sidebarActive);
  };

  // update dark mode when changed in browser settings
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (e) => {
      setDarkMode(e.matches);
    });

  const theme = buildTheme({ darkMode });

  const state: LayoutContextProps = {
    darkMode,
    theme,
    sidebarActive,
    layoutActions: {
      switchDarkMode,
      switchSidebar,
    },
  };

  return (
    <LayoutContext.Provider value={state}>
      <MUIThemeProvider theme={theme}>{props.children}</MUIThemeProvider>
    </LayoutContext.Provider>
  );
};

export const useLayout = () => React.useContext(LayoutContext);
