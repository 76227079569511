import Notification from "../Notification/Notification.component";
import { ReportProblemOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import BlockchainNetworkFetchFailedNotification from "./BlockchainNetworkFetchFailedNotification.component";

const DataServiceDisconnectedNotification: React.FC = (props) => {
  const { t } = useTranslation();

  const { status } = useBlockchainNetwork().remoteConfigRequest;

  if (status !== "fetched") {
    return <BlockchainNetworkFetchFailedNotification />;
  }

  return (
    <Notification
      title={t("messages.networkSettings.disconnected.title")}
      description={t("messages.networkSettings.disconnected.description")}
      Icon={ReportProblemOutlined}
      iconColor="warning"
    />
  );
};

export default DataServiceDisconnectedNotification;
