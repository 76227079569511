import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { QrCodeScanner } from "@mui/icons-material";

import { Recipient, ISendTokenForm } from "../../SendTokenForm.component";
import { isRecipientAttrValid } from "../helpers";

interface AddressInputProps {
  index: number;
  recipient: Recipient;
  formik: FormikProps<ISendTokenForm>;
  onScanButtonClick: (index: number) => void;
}

const AddressInput = (props: AddressInputProps) => {
  const { t } = useTranslation();
  const { index, recipient, onScanButtonClick } = props;
  const { errors, handleChange } = props.formik;

  const isValid = isRecipientAttrValid(errors, index, "address");

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{t("forms.sendToken.fields.address.placeholder")}</InputLabel>
      <OutlinedInput
        name={`recipients[${index}].address`}
        type="text"
        value={recipient.address}
        onChange={handleChange}
        error={!isValid}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title={t("common.scanQrCode") ?? "Scan QR Code"}>
              <IconButton onClick={() => onScanButtonClick(index)}>
                <QrCodeScanner />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        label={t("forms.sendToken.fields.address.placeholder")}
      />
    </FormControl>
  );
};

export default AddressInput;
