import React from "react";
import KeeperDetectorModal from "./KeeperDetectorModal/KeeperDetectorModal.component";
import OrgVerifierModal from "./OrgVerifierModal/OrgVerifierModal.component";
import SendTokenModal from "./SendTokenModal/SendTokenModal.component";
import ReissueTokenModal from "./ReissueTokenModal/ReissueTokenModal.component";
import TransferKeyModal from "./TransferKeyModal/TransferKeyModal.component";
import ShowDeviceModal from "./ShowDeviceModal/ShowDeviceModal.component";
import DeviceMapModal from "./DeviceMapModal/DeviceMapModal.component";
import ConfirmBurnDeviceKeysModal from "./ConfirmBurnDeviceKeysModal/ConfirmBurnDeviceKeysModal.component";

const Modals = () => {
  return (
    <React.Fragment>
      <KeeperDetectorModal />
      <OrgVerifierModal />
      <SendTokenModal />
      <ReissueTokenModal />
      <TransferKeyModal />
      <ShowDeviceModal />
      <DeviceMapModal />
      <ConfirmBurnDeviceKeysModal />
    </React.Fragment>
  );
};

export default Modals;
