import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ITokensCountChip {
  available: number;
  total: number;
}

const TokensCountChip: React.FC<ITokensCountChip> = ({ available, total }) => {
  const { t } = useTranslation();

  const content = t("forms.sendToken.header.balance", {
    balance: `${available} / ${total}`,
  });
  const color = available > 0 ? "success" : "error";

  return <Chip color={color} label={content} />;
};

export default TokensCountChip;
