export function getKey<T>(key: string, defaultValue: T): T {
  const localStorageValue = localStorage.getItem(key);

  if (localStorageValue) {
    return JSON.parse(localStorageValue);
  } else {
    localStorage.setItem(key, JSON.stringify(defaultValue));
    return defaultValue;
  }
}

export function setKey<T>(key: string, setValue: T) {
  localStorage.setItem(key, JSON.stringify(setValue));
  return setValue;
}
