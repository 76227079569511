import { useState, useMemo, useEffect } from "react";
import { BlockchainNetworkConfig } from "../../../providers/BlockchainNetworkProvider";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";

import { ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { Build } from "@mui/icons-material";

import NetworkSettingsModal from "../../Modals/NetworkSettingsModal/NetworkSettingsModal.component";
import { useTranslation } from "react-i18next";

const NetworkSettings: React.FC<any> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [previousName, setPreviousName] = useState<string | undefined>();
  const { name, setCustomNetwork, network, remoteConfigRequest } =
    useBlockchainNetwork();

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isCustomNetwork = useMemo(() => {
    return remoteConfigRequest.status === "fetched" && name === "custom";
  }, [name, remoteConfigRequest.status]);

  useEffect(() => {
    if (previousName === name) return;

    const url = network?.dataService.url;

    if (isCustomNetwork && (!url || url.length === 0)) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    setPreviousName(name);
  }, [previousName, name, isCustomNetwork, network]);

  const handleSubmit = useMemo(() => {
    return async (form: BlockchainNetworkConfig) => {
      setCustomNetwork(form);
      setOpen(false);
    };
  }, [setCustomNetwork]);

  return (
    <>
      {isCustomNetwork && (
        <ListItem button onClick={handleOpen} disabled={!isCustomNetwork}>
          <ListItemIcon>
            <Build />
          </ListItemIcon>
          <ListItemText>{t("interface.sidebar.networkSettings")}</ListItemText>
        </ListItem>
      )}

      <NetworkSettingsModal
        show={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default NetworkSettings;
