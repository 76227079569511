import { Chip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import render from "./attributesRenderer";

import { Device } from "../Device";

const blacklist = [
  "address",
  "lat",
  "lng",
  "alt",
  "name",
  "description",
  "details",
  "custom",
];

const ShowDeviceBasics = (props: { device: Device }) => {
  const { t, i18n } = useTranslation();
  const { device } = props;

  if (!device) return null;

  const booleanDecorator = (key: string, value: boolean) => {
    const valueStr: string = value === undefined ? "false" : value.toString();

    return (
      <Chip
        variant="filled"
        color={value ? "success" : "error"}
        label={t(`tables.devices.enums.${key}.${valueStr}`)}
      />
    );
  };

  const localizeDate = (date: string) => {
    return new Date(Date.parse(date)).toLocaleString(i18n.language);
  };

  const showDevice: { [key: string]: any } = { ...device };

  blacklist.forEach((item: string) => {
    delete showDevice[item];
  });

  showDevice.visible = booleanDecorator("visible", device.visible);
  showDevice.connected = booleanDecorator("connected", device.connected);

  if (showDevice.createdAt) {
    showDevice.createdAt = localizeDate(showDevice.createdAt);
  }

  if (showDevice.updatedAt) {
    showDevice.updatedAt = localizeDate(showDevice.updatedAt);
  }

  return (
    <Grid container spacing={1}>
      {render(showDevice, t, "device.model", blacklist)}
    </Grid>
  );
};

export default ShowDeviceBasics;
