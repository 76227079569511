import { TFunction } from "i18next";
import { User } from "../User";
import buildColumns from "./Columns";
import renderTable from "../../UI/MUITable/Renderer";

const render = (users: User[], t: TFunction, showModal: () => void) => {
  return renderTable({
    mode: "local",
    data: users,
    columns: buildColumns(users, t),
    options: {
      addItemButton: true,
      addItemButtonLabel: t("buttons.users.create"),
      searchInput: true,
      searchInputLabel: t("tables.search.placeholder"),
      onAddItem: showModal,
    },
  });
};

export default render;
