import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import render from "./attributesRenderer";

import { DevicePhysicalAddress } from "../Device";

const ShowDeviceAddress = (props: {
  physicalAddress?: DevicePhysicalAddress;
}) => {
  const { t } = useTranslation();
  const { physicalAddress } = props;

  if (!physicalAddress) return null;

  return (
    <Grid container spacing={1}>
      {render(physicalAddress, t, "device.model.details.address")}
    </Grid>
  );
};

export default ShowDeviceAddress;
