import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../../UI/Modal/Modal.component";
import { IRootReducer } from "../../../reducers";
import { closeShowDevice } from "../../../actions/devicesActions";
import ShowDevice from "../../Devices/DeviceDetails/ShowDevice.component";
import { Button, ButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

const TransferKeyModal = () => {
  const dispatch = useDispatch();
  const device = useSelector((state: IRootReducer) => state.devices.show);
  const { t } = useTranslation();

  const onClose = () => {
    dispatch(closeShowDevice());
  };

  let content = null;
  let footer = null;

  if (device) {
    content = (
      <React.Fragment>
        <ShowDevice />
      </React.Fragment>
    );

    footer = (
      <ButtonGroup>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t("buttons.close")}
        </Button>
      </ButtonGroup>
    );
  }

  return (
    <Modal
      closable={true}
      show={device !== undefined}
      footer={footer}
      handleClose={onClose}
    >
      {content}
    </Modal>
  );
};

export default TransferKeyModal;
