import { Theme } from "@mui/material";

const WARNING_THRESHOLD =
  process.env.REACT_APP_KEY_EXPIRATION_WARNING_THRESHOLD || 604800000;
const ALERT_THRESHOLD =
  process.env.REACT_APP_KEY_EXPIRATION_ALERT_THRESHOLD || 259200000;

type ExpirationStatus = "active" | "warning" | "alert" | "expired";

export const pickExpirationStatus = (expiresAt: number): ExpirationStatus => {
  const delta = expiresAt - Date.now();

  if (delta > WARNING_THRESHOLD) {
    return "active";
  } else if (delta > ALERT_THRESHOLD) {
    return "warning";
  } else if (delta > 0) {
    return "alert";
  } else {
    return "expired";
  }
};

const pickExpirationColor = (expiresAt: number, theme: Theme): string => {
  switch (pickExpirationStatus(expiresAt)) {
    case "active":
      return theme.palette.success.main;
    case "warning":
      return theme.palette.warning.main;
    case "alert":
      return theme.palette.error.main;
    case "expired":
      return theme.palette.grey[500];
    default:
      return theme.palette.primary.main;
  }
};

export default pickExpirationColor;
