import { User } from "../../components/Users/User";
import * as transactions from "./helpers/transactions";

export default class KeeperWriteService {
  public keeper: WavesKeeper.TWavesKeeperApi;

  constructor(keeper: WavesKeeper.TWavesKeeperApi) {
    this.keeper = keeper;
  }

  publishTransaction = async (
    tx: WavesKeeper.TSignTransactionData
  ): Promise<any> => {
    if (!this.keeper) {
      throw new Error("keeper not installed");
    }

    return this.keeper
      .signAndPublishTransaction(tx)
      .then((res: string) => {
        const tx = JSON.parse(res);
        console.log(`Published transaction with ID: ${tx.id}`);
        console.log(tx);
        return Promise.resolve(res);
      })
      .catch((e: any) => {
        console.log(`Failed to publish transaction`);
        console.log(e);
        return Promise.reject(e);
      });
  };

  setScript = async (rawScript: string): Promise<any> => {
    return await this.publishTransaction(transactions.setScript(rawScript));
  };

  burnToken = async (assetId: string, amount: number): Promise<any> => {
    return await this.publishTransaction(
      transactions.burnTransactionData(assetId, amount)
    );
  };

  transferAsset = async (params: transactions.TransferParams): Promise<any> => {
    return this.publishTransaction(
      transactions.transferTransactionData(params)
    );
  };

  activateToken = async (assetId: string) => {
    return this.publishTransaction(
      transactions.activateTransactionData(assetId, true)
    );
  };

  deactivateToken = async (assetId: string) => {
    return this.publishTransaction(
      transactions.activateTransactionData(assetId, false)
    );
  };

  activateUser = async (user: User) => {
    return this.publishTransaction(
      transactions.activateUserData(user.address, true)
    ).then((_) => {
      return {
        ...user,
        active: true,
      };
    });
  };

  deactivateUser = async (user: User) => {
    return this.publishTransaction(
      transactions.activateUserData(user.address, false)
    ).then((_) => {
      return {
        ...user,
        active: false,
      };
    });
  };

  addUser = async (user: User): Promise<User> => {
    return this.publishTransaction(transactions.addUserData(user)).then(() => {
      return user;
    });
  };

  updateUserNote = async (user: User): Promise<User> => {
    return this.publishTransaction(transactions.updateUserNote(user)).then(
      () => {
        return { ...user };
      }
    );
  };

  removeUser = async (user: User): Promise<User> => {
    return this.publishTransaction(
      transactions.removeUserData(user.address)
    ).then(() => {
      return user;
    });
  };
}
