import { Table, TableContainer, Paper, Box } from "@mui/material";
import React from "react";
import CustomTableBody from "./CustomTableBody";
import CustomTableBottom from "./CustomTableBottom";
import CustomTableHead from "./CustomTableHead";
import CustomTableTop from "./CustomTableTop";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { CustomTableProps, TableInstanceProps } from "./ITable";

const CustomTable: React.FC<CustomTableProps> = (props) => {
  const { data, columns } = props;

  const instance = useTable(
    { columns, data } as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const instanceProps: TableInstanceProps = {
    instance,
    options: props.options ?? {},
    mode: props.mode,
    onTableChange: props.onTableChange ?? (() => {}),
    state: props.state!,
    rowFormatter: props.rowFormatter,
  };

  const component = props.component ?? "Box" ? Box : Paper;

  return (
    <TableContainer component={component} sx={{ padding: "10px" }}>
      <CustomTableTop {...instanceProps} />
      <Table>
        <CustomTableHead {...instanceProps} />
        <CustomTableBody {...instanceProps} />
      </Table>
      <CustomTableBottom {...instanceProps} />
    </TableContainer>
  );
};

export default CustomTable;
