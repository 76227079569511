/// <reference types="google.maps" />

import { gql } from "graphql-request";

export const getKeysQuery = gql`
  query GetKeys(
    $take: Int!
    $skip: Int!
    $order: String!
    $orderBy: String!
    $filter: KeysFilter
    $search: String
  ) {
    keys(
      take: $take
      skip: $skip
      order: $order
      orderBy: $orderBy
      filter: $filter
      search: $search
    ) {
      objects {
        assetId
        issuer
        owner
        name
        device
        validTo
        issueTimestamp
        burned
        deviceName
      }
      meta {
        total
      }
    }
  }
`;

// getAllDevices

export const getDevicesQuery = gql`
  query GetDevices(
    $take: Int!
    $skip: Int!
    $order: String
    $orderBy: String
    $filter: DevicesFilter
    $geoSearch: GeoSearchInput
    $keysOwner: String
    $search: String
  ) {
    devices(
      take: $take
      skip: $skip
      order: $order
      orderBy: $orderBy
      filter: $filter
      geoSearch: $geoSearch
      keysOwner: $keysOwner
      search: $search
    ) {
      objects {
        address
        name
        visible
        connected
        lat
        lng
        details {
          deviceType
          deviceModel
        }
        key {
          assetId
          name
          validTo
          createdAt
          updatedAt
        }
      }
      meta {
        total
      }
    }
  }
`;

// GetDevice

export const getDeviceQuery = (address: string) => gql`
  {
    device(address: "${address}") {
      address
      supplier
      owner
      name
      description
      lat
      lng
      alt
      visible
      connected
      details {
        deviceType
        deviceModel
        additionalDescription
        assetUrl
        url
        contactInfo
        physicalAddress {
          addressLine1
          addressLine2
          city
          postcode
          state
          country
          number
          floor
        }
      }
      custom
      createdAt
      updatedAt
    }
  }
`;

export const getDeviceKeysQuery = gql`
  query GetDeviceKeys($owner: String!, $device: String!) {
    keys(
      order: "asc"
      orderBy: "address"
      skip: 0
      take: 100
      filter: { owner: $owner, device: $device, burned: false }
    ) {
      objects {
        assetId
        name
        validTo
        createdAt
        updatedAt
      }
    }
  }
`;
