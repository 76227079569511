import { useState, useEffect, useCallback } from "react";
import axios from "axios";

export type RequestStatus = "init" | "fetching" | "fetched" | "error";

interface RemoteRequestState {
  status: RequestStatus;
  error?: any;
  data?: any;
}

const useRemoteRequest = (url: string) => {
  const [state, setState] = useState<RemoteRequestState>({ status: "init" });

  const updateState = useCallback((vars: Partial<RemoteRequestState>) => {
    setState((oldState) => ({ ...oldState, ...vars }));
  }, []);

  const reset = useCallback(() => {
    updateState({
      status: "init",
      error: undefined,
      data: undefined,
    });
  }, [updateState]);

  const fetch = useCallback(async () => {
    updateState({ status: "fetching" });

    return axios.get(url, { responseType: "json" }).then((res) => {
      updateState({ status: "fetched", data: res.data, error: undefined });
      return res.data;
    });
  }, [updateState, url]);

  const refetch = useCallback(() => {
    reset();

    fetch().catch((e) => {
      updateState({ status: "error", data: undefined, error: e });
    });
  }, [reset, fetch, updateState]);

  useEffect(() => {
    if (state.status !== "init") return;

    fetch().catch((e) => {
      updateState({ status: "error", data: undefined, error: e });
    });
  }, [state.status, fetch, updateState]);

  return {
    state,
    reset,
    refetch,
  };
};

export default useRemoteRequest;
