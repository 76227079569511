import { useEffect, useState } from "react";
import { useKeeper } from "../providers/KeeperProvider/KeeperProvider";

const usePublicAddress = () => {
  const [publicAddress, setPublicAddress] = useState<string | undefined>();
  const { publicState } = useKeeper();

  useEffect(() => {
    const currentAddress = publicState?.account?.address;
    if (currentAddress === publicAddress) return;

    setPublicAddress(currentAddress);
  }, [publicState, publicAddress]);

  return publicAddress;
};

export default usePublicAddress;
