import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IRootReducer } from "../../../reducers";
import { keysFetched } from "../../../actions/keysActions";

import useStatusVerifier from "../../../hooks/useStatusVerifier";
import usePublicAddress from "../../../hooks/usePublicAddress";

import Spinner from "../../UI/Spinner/Spinner.component";
import Renderer from "./Renderer";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import DataServiceDisconnectedNotification from "../../UI/Notifications/DataServiceDisconnectedNotification.component";

const KeysTable = () => {
  const dispatch = useDispatch();
  const { read } = useBlockchainNetwork();
  const [fetched, setFetched] = useState<boolean>(false);

  const { data } = useSelector((state: IRootReducer) => state.keys);
  const publicAddress = usePublicAddress();
  const statusVerified = useStatusVerifier();

  const handleFetchError = (e: any) => {
    setFetched(true);
  };

  const fetchKeysRequest = useCallback(() => {
    if (!read) return;

    const { request } = read.getKeys({
      page: 1,
      perPage: 10,
      order: "asc",
      orderBy: "assetId",
      filter: {
        owner: publicAddress,
      },
    });

    return request;
  }, [publicAddress, read]);

  useEffect(() => {
    if (!statusVerified) return;

    try {
      if (!fetchKeysRequest) return;
      // @ts-ignore
      fetchKeysRequest()
        .then(({ keys, meta }) => {
          dispatch(keysFetched(keys, meta));
          setFetched(true);
        })
        .catch((e) => {
          handleFetchError(e);
        });
    } catch (e) {
      handleFetchError(e);
      setFetched(true);
    }
  }, [fetchKeysRequest, statusVerified, dispatch]);

  let content: JSX.Element = <Spinner />;

  if (!read) {
    content = <DataServiceDisconnectedNotification />;
  } else if (fetched) {
    content = <Renderer rows={Object.values(data)} />;
  }

  return content;
};

export default KeysTable;
