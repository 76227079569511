import React, { useState } from "react";

import {
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  Box,
  Typography,
} from "@mui/material";

import * as IdentityImg from "identity-img";
import { useTranslation } from "react-i18next";

import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";

const AccountMenu = () => {
  const { t } = useTranslation();
  const { publicState } = useKeeper();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderImage = (address: string) => {
    if (!address) return null;

    return (
      <Avatar sx={{ width: 32, height: 32 }}>
        <img
          src={IdentityImg.create(address as string, {
            size: 120,
            rows: 8,
            cells: 8,
          })}
          width={32}
          height={32}
          alt="avatar"
        />
      </Avatar>
    );
  };

  let dropdown: JSX.Element = <></>;

  if (publicState?.account) {
    dropdown = (
      <React.Fragment>
        <Typography>{publicState.account?.name}</Typography>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Account Settings">
            <IconButton onClick={handleClick}>
              {renderImage(publicState.account?.address as string)}
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <Typography textAlign="center">
                {t("interface.header.balance", {
                  balance:
                    parseInt(
                      publicState.account?.balance?.available as string
                    ) /
                    10 ** 8,
                })}
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </React.Fragment>
    );
  }

  return dropdown;
};

export default AccountMenu;
