import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import { Device } from "../../Device";

const VisibleFormatter = (props: { device: Device }) => {
  const { t } = useTranslation();

  const label = t(
    `tables.devices.enums.visible.${props.device.visible.toString()}`
  );

  return (
    <Chip
      variant="filled"
      size="small"
      label={label}
      color={props.device.visible ? "success" : "error"}
    />
  );
};

export default VisibleFormatter;
