import { useEffect, useState } from "react";
import { useKeeper } from "../providers/KeeperProvider/KeeperProvider";

const useStatusVerifier = () => {
  const [verified, setVerified] = useState<boolean>(false);

  const { status } = useKeeper();

  useEffect(() => {
    setVerified(status === "connected");
  }, [status]);

  return verified;
};

export default useStatusVerifier;
