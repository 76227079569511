import { TableBody, TableRow, TableCell } from "@mui/material";
import React from "react";
import { Row } from "react-table";
import { TableInstanceProps } from "./ITable";

const CustomTableBody: React.FC<TableInstanceProps> = ({
  instance,
  mode,
  rowFormatter,
}) => {
  const dataSource = mode === "local" ? instance.page : instance.rows;

  return (
    <TableBody>
      {dataSource.map((row: Row<any>) => {
        instance.prepareRow(row);

        let customProps = {};

        if (rowFormatter) {
          customProps = rowFormatter(row);
        }

        return (
          <TableRow {...row.getRowProps()} sx={{ ...customProps }}>
            {row.cells.map((cell, index) => (
              <TableCell
                {...cell.getCellProps()}
                align={index === 0 ? "left" : "right"}
              >
                {cell.render("Cell")}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default CustomTableBody;
