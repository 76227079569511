import { TFunction } from "i18next";
import { Dispatch } from "react";
import { Column } from "react-table";

import { showDevice } from "../../../actions/devicesActions";
import renderActions from "./DeviceActions";
import VisibleFormatter from "./Formatters/VisibleFormatter";
import ConnectedFormatter from "./Formatters/ConnectedFormatter";
import ValidToFormatter from "./Formatters/ValidToFormatter";
import DataServiceReadService from "../../../providers/BlockchainNetworkProvider/DataServiceReadService";
import { CellArgument } from "../../UI/MUITable/ITable";
import { Device } from "../Device";

const buildColumns = (
  dispatch: Dispatch<any>,
  t: TFunction,
  readService?: DataServiceReadService
): Column<Device>[] => {
  const onAddressClicked = (address: string) => {
    if (!readService) return;
    dispatch(showDevice(readService, address));
  };

  return [
    {
      accessor: "name",
      Header: t("tables.devices.columns.name") ?? "name",
    },
    {
      accessor: "address",
      Header: t("tables.devices.columns.address") ?? "address",
      Cell: (arg: CellArgument<Device>) => {
        return (
          <button
            className="btn btn-link btn-sm"
            onClick={() => onAddressClicked(arg.row.original.address)}
          >
            {arg.row.original.address}
          </button>
        );
      },
    },
    {
      accessor: "visible",
      Header: t("tables.devices.columns.visible") ?? "visible",
      Cell: (arg: CellArgument<Device>) => (
        <VisibleFormatter device={arg.row.original} />
      ),
    },
    {
      accessor: "connected",
      Header: t("tables.devices.columns.connected") ?? "connected",
      Cell: (arg: CellArgument<Device>) => (
        <ConnectedFormatter device={arg.row.original} />
      ),
    },
    {
      accessor: "key.validTo" as any,
      Header: t("tables.devices.columns.validTo") ?? "Valid To",
      Cell: (arg: CellArgument<Device>) => (
        <ValidToFormatter device={arg.row.original} />
      ),
    },
    {
      id: "actions",
      Header: t("tables.devices.columns.actions") ?? "actions",
      Cell: (arg: CellArgument<Device>) => renderActions(arg.row.original),
    },
  ];
};

export default buildColumns;
