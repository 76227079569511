import { TFunction } from "react-i18next";
import * as Yup from "yup";

import { BLOCKCHAIN_ADDRESS_REGEXP } from "../../../../helpers/regexp";
import { User } from "../../User";

const getLabel = (t: TFunction, field: string) => {
  return t(`forms.user.fields.${field}.label`);
};

const addressEditValidation = (t: TFunction) => {
  return Yup.string().matches(
    BLOCKCHAIN_ADDRESS_REGEXP,
    t("forms.validation.blockchainAddress.invalid", {
      field: getLabel(t, "address"),
    })
  );
};

const addressAddValidation = (t: TFunction, users: { [key: string]: User }) => {
  return addressEditValidation(t)
    .required(
      t("forms.validation.required", {
        field: getLabel(t, "address"),
      })
    )
    .test(
      "address-uniqueness",
      t("forms.validation.string.exists", {
        field: getLabel(t, "address"),
      }),
      (add) => {
        if (!add) return false;

        return !users[add];
      }
    );
};

const mobileIdEditValidation = () => {
  return Yup.string();
};

const mobileIdAddValidation = (
  t: TFunction,
  users: { [key: string]: User }
) => {
  return mobileIdEditValidation().test(
    "mobileId-uniqueness",
    t("forms.validation.string.exists", {
      field: getLabel(t, "mobileId"),
    }),
    (add) => {
      if (!add || add === "?" || add === "*") return true;

      const existing = Object.values(users).find((user) => {
        return user.mobileId === add;
      });

      return !existing;
    }
  );
};

const noteValidation = (t: TFunction) => {
  return Yup.string()
    .optional()
    .max(
      1000,
      t("forms.validation.string.max", {
        field: getLabel(t, "note"),
        max: 1000,
      })
    );
};

export const buildValidationSchema = (
  t: TFunction,
  users: { [key: string]: User }
) => {
  return Yup.object().shape({
    address: addressAddValidation(t, users),
    mobileId: mobileIdAddValidation(t, users),
    note: noteValidation(t),
  });
};

export const buildEditValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    address: addressEditValidation(t),
    mobileId: mobileIdEditValidation(),
    note: noteValidation(t),
  });
};
