import { FormikProps, FieldArrayRenderProps } from "formik";
// import { Box } from "@mui/material";
import { Grid, Stack } from "@mui/material";

import { ISendTokenForm, Recipient } from "../SendTokenForm.component";

import AddressInput from "./Inputs/AddressInput.component";
// import AmountInput from "./Inputs/AmountInput.component";
import RemoveItem from "./Inputs/RemoveItem.component";
import RecipientErrorMessage from "./Inputs/RecipientErrorMessage.component";

interface RecipientRowProps {
  index: number;
  recipient: Recipient;
  formik: FormikProps<ISendTokenForm>;
  onQrScanButtonClicked: (index: number) => void;
  arrayHelpers: FieldArrayRenderProps;
}

const RecipientRow = (props: RecipientRowProps) => {
  const { index, recipient, formik, onQrScanButtonClicked, arrayHelpers } =
    props;

  return (
    <div key={index}>
      <Grid
        container
        sx={{ marginBottom: "1em" }}
        columnSpacing={2}
        alignItems="center"
      >
        <Grid item xs={11}>
          <AddressInput
            index={index}
            recipient={recipient}
            formik={formik}
            onScanButtonClick={onQrScanButtonClicked}
          />
        </Grid>

        <Grid item xs={1}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <RemoveItem index={index} arrayHelpers={arrayHelpers} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <RecipientErrorMessage
            attribute="address"
            index={index}
            errors={formik.errors}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default RecipientRow;
