import { ListItem, ListItemText, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../providers/LayoutProvider/LayoutProvider";

const DarkModeToggle: React.FC = () => {
  const { darkMode, layoutActions } = useLayout();
  const { t } = useTranslation();

  return (
    <ListItem>
      <ListItemText>{t("interface.sidebar.darkMode")}</ListItemText>
      <Switch
        onChange={() => layoutActions.switchDarkMode()}
        checked={darkMode}
      />
    </ListItem>
  );
};

export default DarkModeToggle;
