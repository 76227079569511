import React from "react";

import {
  Dialog as MaterialModal,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Breakpoint,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface IModal {
  show?: boolean;
  handleClose?: () => any;
  closable?: boolean;
  title?: string;
  footer?: JSX.Element | null;
  maxWidth?: Breakpoint;
}

const defaults: IModal = {
  show: false,
  closable: true,
};

const Modal: React.FunctionComponent<IModal> = (props) => {
  props = { ...defaults, ...props };

  let title = null;
  let footer = null;
  let closeButton = null;

  if (props.closable) {
    closeButton = (
      <IconButton onClick={props.handleClose} style={{ padding: "0px" }}>
        <CloseIcon />
      </IconButton>
    );
  }

  if (props.title) {
    title = (
      <>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "30px",
          }}
        >
          {props.title}
          {closeButton}
        </DialogTitle>
      </>
    );
  }

  if (props.footer) {
    footer = <DialogActions>{props.footer}</DialogActions>;
  }

  return (
    <>
      <MaterialModal
        open={props.show!}
        maxWidth={props.maxWidth || "md"}
        fullWidth
        onClose={props.handleClose}
      >
        {title}
        <DialogContent>{props.children}</DialogContent>
        {footer}
      </MaterialModal>
    </>
  );
};

export default Modal;
