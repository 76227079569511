import { IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { Device } from "../../Device";
import { KeyCollection } from "../../../Keys/Key";
import usePublicAddress from "../../../../hooks/usePublicAddress";
import { deviceKeysBurnInit } from "../../../../actions/devicesActions";
import { useBlockchainNetwork } from "../../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import { useTranslation } from "react-i18next";

const BurnKeysButton = (props: { device: Device }): JSX.Element => {
  const dispatch = useDispatch();
  const address = usePublicAddress();
  const { device } = props;
  const { read } = useBlockchainNetwork();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const burnKeysInit = () => {
    if (!address || !read) return;
    read
      .getDeviceKeys(address, device.address)
      .then((res: KeyCollection) => {
        dispatch(deviceKeysBurnInit(device.address, res));
      })
      .catch((e: any) => {
        enqueueSnackbar(
          t("messages.deviceKeys.fetchFailed", {
            defaultValue: "Failed to fetch keys of device: " + e.message,
            message: e.message,
          }),
          { variant: "error" }
        );
      });
  };

  return (
    <Tooltip
      title={t("tables.devices.actions.deleteKeys") || "Delete all keys"}
    >
      <IconButton color="error" onClick={burnKeysInit}>
        <Delete />
      </IconButton>
    </Tooltip>
  );
};

export default BurnKeysButton;
