import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TableChangeType, TableChangeState } from "react-bootstrap-table-next";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";
import { ClientError } from "graphql-request";
import { Row } from "react-table";

import buildColumns from "./Columns";
import { IRootReducer } from "../../../reducers";
import { Key } from "../Key";
import { keysFetched } from "../../../actions/keysActions";
import usePublicAddress from "../../../hooks/usePublicAddress";
import { KeysPaginationParams } from "../../../providers/BlockchainNetworkProvider/DataServiceReadService";
import useDataServiceTableHandler from "../../../hooks/useDataServiceTableHandler";
import { searchToSearchQuery } from "../../../helpers/helpers";
import renderTable from "../../UI/MUITable/Renderer";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import keyRowFormatter from "../helpers/keyRowFormatter";
import { useLayout } from "../../../providers/LayoutProvider/LayoutProvider";

const Renderer = (props: { rows: Key[] }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { read } = useBlockchainNetwork();
  const { meta } = useSelector((state: IRootReducer) => state.keys);
  const publicAddress = usePublicAddress();
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useLayout();

  const { lastAction, queryParams, tableChanged } =
    useDataServiceTableHandler<Key>({
      defaults: {
        order: "asc",
        orderBy: "name",
      },
    });

  // Fetch records with current parameters
  //
  const fetchKeys = useCallback(
    (params: KeysPaginationParams) => {
      if (!params.filter?.owner || !read) return;

      read
        .getKeys(params)
        .request.then(({ keys, meta }) => {
          dispatch(keysFetched(keys, meta));
        })
        .catch((error: ClientError) => {
          if (!error.response.errors) return;

          enqueueSnackbar(
            t("messages.keys.fetchFailed", {
              message: error.response.errors
                .map((e: any) => e.message)
                .join(", "),
            }),
            { variant: "error" }
          );
        });
    },
    [t, dispatch, read, enqueueSnackbar]
  );

  // useEffect to run when search is changed
  //
  useEffect(() => {
    if (lastAction !== "searchChanged") return;

    const params = {
      ...queryParams,
      search: searchToSearchQuery(queryParams.search),
      filter: { owner: publicAddress },
    };

    const debounceFetch = debounce(() => {
      fetchKeys(params);
    }, 500);

    debounceFetch();

    return () => {
      debounceFetch.cancel();
    };
  }, [lastAction, fetchKeys, publicAddress, queryParams]);

  // useEffect to run when search is NOT changed
  //
  useEffect(() => {
    if (lastAction !== "searchNotChanged") return;

    const params = {
      ...queryParams,
      search: searchToSearchQuery(queryParams.search),
      filter: { owner: publicAddress },
    };

    fetchKeys(params);
  }, [lastAction, fetchKeys, publicAddress, queryParams]);

  // Event to run when any table attribute changes
  const handleTableChange = (
    type: TableChangeType,
    e: TableChangeState<Key> & { searchText?: string }
  ): void => {
    if (!publicAddress) return;

    tableChanged(e);
  };

  // Formatting of table rows
  const rowFormatter = (row: Row<Key>) => {
    const key = row.original;

    return keyRowFormatter(key, theme);
  };

  return renderTable({
    mode: "server",
    data: props.rows,
    columns: buildColumns(t),
    options: {
      searchInput: true,
      searchInputLabel: t("tables.search.placeholder"),
    },
    onTableChange: handleTableChange as any,
    state: {
      page: queryParams.page,
      sizePerPage: queryParams.perPage,
      totalSize: meta.total,
      orderBy: queryParams.orderBy,
      order: queryParams.order,
    },
    rowFormatter,
  });
};

export default Renderer;
