import {
  Token,
  TokenEntry,
  TokenCollection,
  TokenEntryCollection,
} from "../components/Tokens/Token";
import {
  TOKENS_FETCHED,
  TOKEN_ENTRIES_FETCHED,
  TOKEN_CREATED,
  TOKEN_ACTIVATED,
  TOKEN_DEACTIVATED,
  TOKEN_BURNED,
  TOKEN_SEND_INIT,
  TOKEN_SEND_CANCEL,
  TOKEN_TRANSFERED,
  TOKEN_ENTRY_DELETED,
  TOKEN_ENTRY_ACTIVATED,
  TOKEN_ENTRY_DEACTIVATED,
  TOKEN_REISSUE_INIT,
  TOKEN_REISSUE_CANCEL,
  TOKEN_REISSUED,
} from "./types";

const defaultTokenAction = (type: string, token: Token) => {
  return {
    type: type,
    payload: {
      token,
    },
  };
};

const defaultTokenEntryAction = (type: string, tokenEntry: TokenEntry) => {
  return {
    type: type,
    payload: { tokenEntry },
  };
};

export const tokensFetched = (tokens: TokenCollection) => {
  return {
    type: TOKENS_FETCHED,
    payload: {
      tokens,
    },
  };
};

export const tokenCreated = (token: Token) => {
  return defaultTokenAction(TOKEN_CREATED, token);
};

export const tokenActivated = (token: Token) => {
  return defaultTokenAction(TOKEN_ACTIVATED, token);
};

export const tokenDeactivated = (token: Token) => {
  return defaultTokenAction(TOKEN_DEACTIVATED, token);
};

export const tokenBurned = (token: Token) => {
  return defaultTokenAction(TOKEN_BURNED, token);
};

export const sendTokenInit = (token: Token) => {
  return defaultTokenAction(TOKEN_SEND_INIT, token);
};

export const sendTokenCancel = () => {
  return {
    type: TOKEN_SEND_CANCEL,
  };
};

export const tokensSent = (tx: any) => {
  return {
    type: TOKEN_TRANSFERED,
    payload: {
      transaction: tx,
    },
  };
};

export const reissueTokenInit = (token: Token) => {
  return defaultTokenAction(TOKEN_REISSUE_INIT, token);
};

export const reissueTokenCancel = () => {
  return {
    type: TOKEN_REISSUE_CANCEL,
  };
};

export const tokenReissued = (token: Token) => {
  return defaultTokenAction(TOKEN_REISSUED, token);
};

export const tokenEntriesFetched = (tokenEntries: TokenEntryCollection) => {
  return {
    type: TOKEN_ENTRIES_FETCHED,
    payload: {
      tokenEntries,
    },
  };
};

export const tokenEntryActivated = (tokenEntry: TokenEntry) => {
  return defaultTokenEntryAction(TOKEN_ENTRY_ACTIVATED, tokenEntry);
};

export const tokenEntryDeactivated = (tokenEntry: TokenEntry) => {
  return defaultTokenEntryAction(TOKEN_ENTRY_DEACTIVATED, tokenEntry);
};

export const tokenEntryDeleted = (tokenEntry: TokenEntry) => {
  return defaultTokenEntryAction(TOKEN_ENTRY_DELETED, tokenEntry);
};
