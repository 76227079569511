import { Key } from "../../Keys/Key";
import { Tooltip, Chip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import pickExpirationColor, {
  pickExpirationStatus,
} from "../../../helpers/keyExpirationThreshold";
import useFormattedTime from "../../../hooks/useFormattedTime";
import useFormattedRelativeTime from "../../../hooks/useFormattedRelativeTime";

interface KeyExpirationBadgeProps {
  deviceKey: Key;
}

const KeyExpirationBadge: React.FC<KeyExpirationBadgeProps> = ({
  deviceKey,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const status = pickExpirationStatus(deviceKey.validTo);
  const color = pickExpirationColor(deviceKey.validTo, theme);

  const formattedExpirationTime = useFormattedTime(deviceKey.validTo);
  const formattedRelativeTime = useFormattedRelativeTime(
    deviceKey.validTo,
    Date.now()
  );

  const label = t(
    `messages.keys.${status === "expired" ? "expired" : "expires"}AtRelative`,
    { time: formattedRelativeTime }
  );

  return (
    <Tooltip title={formattedExpirationTime}>
      <Chip
        size="small"
        variant="outlined"
        sx={{
          backgroundColor: color,
          color: theme.palette.getContrastText(color),
        }}
        label={label}
      />
    </Tooltip>
  );
};

export default KeyExpirationBadge;
