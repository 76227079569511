import { AnyAction } from "redux";

import {
  DEVICES_FETCHED,
  DEVICE_SHOW_INIT,
  DEVICE_SHOW_CLOSE,
  SHOW_DEVICE_ON_MAP_INIT,
  SHOW_DEVICE_ON_MAP_CLOSE,
  DEVICE_KEYS_BURN_INIT,
  DEVICE_KEYS_BURN_PARTIALLY_BURNED,
  DEVICE_KEYS_BURN_FINISHED,
  DEVICE_KEYS_BURN_CANCELLED,
} from "../actions/types";
import { Device, DeviceCollection } from "../components/Devices/Device";
import { KeyCollection } from "../components/Keys/Key";
import { PaginationItemsMeta } from "../helpers/helpers";

export interface IDevicesReducer {
  data: DeviceCollection;
  show?: Device;
  showMap?: Device;
  meta: PaginationItemsMeta;
  keysBurn?: { device: string; keys: KeyCollection };
}

const initialValue: IDevicesReducer = {
  data: {},
  meta: { total: 0 },
};

const removeDevices = (
  data: DeviceCollection,
  addresses: string[]
): DeviceCollection => {
  const solution: DeviceCollection = { ...data };

  addresses.forEach((deviceAddress: string) => {
    delete solution[deviceAddress];
  });

  return solution;
};

const decrementTotal = (
  meta: PaginationItemsMeta,
  step: number = 1
): PaginationItemsMeta => {
  const solution: PaginationItemsMeta = { ...meta };

  solution.total = Math.max(0, meta.total - step);

  return solution;
};

const devicesReducer = (
  state: IDevicesReducer = initialValue,
  action: AnyAction
) => {
  switch (action.type) {
    case DEVICES_FETCHED:
      return {
        ...state,
        data: action.payload.devices,
        meta: action.payload.meta,
      };
    case DEVICE_SHOW_INIT:
      return {
        ...state,
        show: action.payload.device,
      };
    case DEVICE_SHOW_CLOSE:
      return {
        ...state,
        show: undefined,
      };
    case SHOW_DEVICE_ON_MAP_INIT:
      return {
        ...state,
        showMap: action.payload.device,
      };
    case SHOW_DEVICE_ON_MAP_CLOSE:
      return {
        ...state,
        showMap: undefined,
      };
    case DEVICE_KEYS_BURN_INIT:
      return {
        ...state,
        keysBurn: action.payload,
      };
    case DEVICE_KEYS_BURN_PARTIALLY_BURNED:
      return {
        ...state,
        keysBurn: undefined,
      };
    case DEVICE_KEYS_BURN_CANCELLED:
      return {
        ...state,
        keysBurn: undefined,
      };
    case DEVICE_KEYS_BURN_FINISHED:
      return {
        ...state,
        keysBurn: undefined,
        data: removeDevices(state.data, [action.payload.device]),
        meta: decrementTotal(state.meta),
      };
    default:
      return state;
  }
};

export default devicesReducer;
