// styles
import "./App.css";

// external libraries
import { Switch, Route } from "react-router-dom";

// hooks
import useOrgVerifier from "./hooks/useOrgVerifier";

// components
import Layout from "./components/Layout/Layout.component";
import Modals from "./components/Modals/Modals";
import Tokens from "./components/Tokens/Tokens.component";
import Users from "./components/Users/Users.component";
import Keys from "./components/Keys/Keys.component";
import Devices from "./components/Devices/Devices.component";
import Dashboard from "./components/Dashboard/Dashboard.component";

function App() {
  useOrgVerifier();

  return (
    <>
      <Modals />
      <div className="App">
        <Layout>
          <Switch>
            <Route path="/tokens">
              <Tokens />
            </Route>

            <Route path="/users">
              <Users />
            </Route>

            <Route path="/keys">
              <Keys />
            </Route>

            <Route path="/devices">
              <Devices />
            </Route>

            <Route path="/" exact>
              <Dashboard />
            </Route>
          </Switch>
        </Layout>
      </div>
    </>
  );
}

export default App;
