import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import Modal from "../UI/Modal/Modal.component";
import TokensTable from "./TokensTable/TokensTable.component";
import TokenEntriesTable from "./TokenEntriesTable/TokenEntriesTable.component";
import TokenForm from "./Forms/CreateTokenForm/TokenForm.component";
import TokenInfoModal from "../Modals/TokenInfoModal/TokenInfoModal.component";

const Tokens = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const { t } = useTranslation();

  function openModal() {
    setShowCreateModal(true);
  }

  function closeModal() {
    setShowCreateModal(false);
  }

  function openInfoModal() {
    setShowInfoModal(true);
  }

  function closeInfoModal() {
    setShowInfoModal(false);
  }

  return (
    <React.Fragment>
      <Grid item xs={12} sx={{ mb: 5 }}>
        <Paper sx={{ p: 3 }}>
          <Grid
            container
            spacing={2}
            sx={{ marginBottom: "1em" }}
            justifyContent="space-between"
          >
            <Grid item xs={10}>
              <Typography variant="h4">
                {t("tokens.ownedTokens.title")}
              </Typography>
              <Typography variant="h6">
                {t("tokens.ownedTokens.description")}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Tooltip title={t("common.info") ?? "Info"}>
                  <IconButton color="info" onClick={openInfoModal}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
          <br />
          <TokensTable onAddItem={openModal} />
        </Paper>
        <Modal
          show={showCreateModal}
          title={t("forms.createToken.header.title")}
          closable={true}
          handleClose={closeModal}
        >
          <TokenForm handleSubmit={closeModal} />
        </Modal>
        <TokenInfoModal open={showInfoModal} onClose={closeInfoModal} />
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h4">{t("tokens.tokenEntries.title")}</Typography>
          <Typography variant="h6">
            {t("tokens.tokenEntries.description")}
          </Typography>
          <br />
          <br />
          <TokenEntriesTable />
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default Tokens;
