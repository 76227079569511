import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import Modal from "../../UI/Modal/Modal.component";
import { IRootReducer } from "../../../reducers";
import TransferForm from "../../Forms/TransferForm/TransferForm.component";
import { keyTransfered, keyTransferCancel } from "../../../actions/keysActions";

const TransferKeyModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { transferKey } = useSelector((state: IRootReducer) => state.keys);

  let content = null;
  let footer = null;

  const onClose = () => {
    dispatch(keyTransferCancel());
  };

  if (transferKey) {
    const callback = (_res: any, _values: any) => {
      dispatch(keyTransfered(transferKey));
    };

    const asset = {
      assetId: transferKey.assetId,
      amount: 1,
    };

    content = (
      <React.Fragment>
        <Typography variant="h4">
          {t("forms.transferKey.header.title")}
        </Typography>
        <Typography variant="h6">{transferKey.assetId}</Typography>
        <TransferForm asset={asset} callback={callback} onClose={onClose} />
      </React.Fragment>
    );
  }

  return (
    <Modal
      closable={true}
      show={transferKey !== undefined}
      footer={footer}
      handleClose={onClose}
    >
      {content}
    </Modal>
  );
};

export default TransferKeyModal;
