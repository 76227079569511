import { Box, Container, CssBaseline, Toolbar, Grid } from "@mui/material";

import Header from "./Header/Header.component";

const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
