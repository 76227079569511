import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Typography, Skeleton } from "@mui/material";
import { EditTwoTone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { User } from "../../User";
import { userEditInit } from "../../../../actions/usersActions";
import { useKeeper } from "../../../../providers/KeeperProvider/KeeperProvider";

const AddressFormatter: React.FC<{ user: User }> = ({ user }) => {
  const { address, note, encNote } = user;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [noteLoading, setNoteLoading] = useState<boolean>(false);
  const [noteError, setNoteError] = useState<string | undefined>();
  const [decryptedNote, setDecryptedNote] = useState<string | undefined>(note);
  const { encryptor } = useKeeper();

  useEffect(() => {
    if (note || !encryptor || !encNote) return;

    setNoteError(undefined);
    setNoteLoading(true);

    encryptor
      .decrypt(encNote)
      .then((res) => {
        setDecryptedNote(res);
        user.note = res;
      })
      .catch((e) => {
        setNoteError(t("messages.users.decryptFailed", { message: e.message }));
      })
      .finally(() => {
        setNoteLoading(false);
      });
  }, [note, encNote, encryptor, t, user]);

  const onEditClicked = () => {
    dispatch(userEditInit(user));
  };

  let noteElement;

  if (noteLoading) {
    noteElement = (
      <Typography variant="subtitle2">
        <Skeleton />
      </Typography>
    );
  } else if (noteError) {
    noteElement = (
      <Typography variant="subtitle2" color="error">
        {noteError}
      </Typography>
    );
  } else if (decryptedNote) {
    noteElement = <Typography variant="subtitle2">{decryptedNote}</Typography>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        columnGap: "10px",
      }}
    >
      <div>
        <IconButton onClick={onEditClicked} size="small" sx={{ opacity: 0.5 }}>
          <EditTwoTone />
        </IconButton>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 2,
          rowGap: "5px",
        }}
      >
        <span
          style={{
            wordBreak: "break-all",
          }}
        >
          {address}
        </span>
        {noteElement}
      </div>
    </div>
  );
};

export default AddressFormatter;
