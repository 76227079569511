import React from "react";
import KeeperService from "./KeeperService";
import KeeperReadService from "./KeeperReadService";
import { WalletStatus } from ".";
import KeeperWriteService from "./KeeperWriteService";
import KeeperMessageEncryptor from "./KeeperMessageEncryptor";

export interface KeeperProviderProps {
  connectOnLoad: boolean;
}

export interface KeeperState {
  status: WalletStatus;
  publicState: WavesKeeper.IPublicStateResponse | null;
  service: KeeperService | null;
  readService: KeeperReadService | null;
  writeService: KeeperWriteService | null;
  keeper: WavesKeeper.TWavesKeeperApi | null;
  encryptor?: KeeperMessageEncryptor;
  connect: () => Promise<any>;
}

export const KeeperContext = React.createContext<KeeperState>(null as any);

export const KeeperProvider: React.FC<KeeperProviderProps> = (props) => {
  const [status, setStatus] = React.useState<WalletStatus>("loading");
  const [publicState, setPublicState] =
    React.useState<WavesKeeper.IPublicStateResponse | null>(null);

  const service = new KeeperService(publicState);

  const readService = React.useMemo(() => {
    const url = publicState?.network?.server;

    return url ? new KeeperReadService(url) : null;
  }, [publicState?.network]);

  const writeService = React.useMemo(() => {
    return service.keeper ? new KeeperWriteService(service.keeper) : null;
  }, [service.keeper]);

  const encryptor = React.useMemo(() => {
    if (!service.keeper || !publicState?.account) return undefined;

    return new KeeperMessageEncryptor(service.keeper, publicState.account);
  }, [service.keeper, publicState?.account]);

  React.useEffect(() => {
    service.waitForKeeper(3000).then((result) => {
      setStatus(result ? "available" : "not_available");
      if (props.connectOnLoad) service.connect(setStatus, setPublicState);
    });
    //eslint-disable-next-line
  }, []);

  const state: KeeperState = {
    status,
    publicState,
    service,
    readService,
    writeService,
    encryptor,
    keeper: service.keeper,
    connect: () => service.connect(setStatus, setPublicState),
  };

  return (
    <KeeperContext.Provider value={state}>
      {props.children}
    </KeeperContext.Provider>
  );
};

export const useKeeper = () => React.useContext(KeeperContext);
