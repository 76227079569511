import { Grid, Typography } from "@mui/material";
import { TFunction } from "react-i18next";

const renderAttributes = (attributes: { [key: string]: any }, t: TFunction) => {
  return Object.entries(attributes).map(([key, value]) => {
    return (
      <Grid item xs={12} key={key}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body1">{key}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "end", wordBreak: "break-word" }}>
            <div>
              {value !== undefined && value !== null && value !== ""
                ? value
                : "None"}
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  });
};

const prepareAttributes = (
  attributes: { [key: string]: any },
  t: TFunction,
  tlKeyPrefix: string,
  blacklist: string[] = []
) => {
  return Object.fromEntries(
    Object.entries(attributes)
      .filter(([key, value]) => {
        return !blacklist.includes(key);
      })
      .map(([key, value]) => {
        return [t(`${tlKeyPrefix}.${key}`), value];
      })
  );
};

export const render = (
  attributes: { [key: string]: any },
  t: TFunction,
  keyPrefix: string,
  blacklist: string[] = []
) => {
  return renderAttributes(
    prepareAttributes(attributes, t, keyPrefix, blacklist),
    t
  );
};

export default render;
