import { AnyAction } from "redux";

import {
  Token,
  TokenEntry,
  TokenCollection,
  TokenEntryCollection,
} from "../components/Tokens/Token";

import {
  TOKEN_ENTRIES_FETCHED,
  TOKENS_FETCHED,
  TOKEN_CREATED,
  TOKEN_ACTIVATED,
  TOKEN_DEACTIVATED,
  TOKEN_BURNED,
  TOKEN_TRANSFERED,
  TOKEN_SEND_INIT,
  TOKEN_SEND_CANCEL,
  TOKEN_ENTRY_DELETED,
  TOKEN_ENTRY_ACTIVATED,
  TOKEN_ENTRY_DEACTIVATED,
  TOKEN_REISSUE_INIT,
  TOKEN_REISSUE_CANCEL,
  TOKEN_REISSUED,
} from "../actions/types";

export interface ITokensReducer {
  tokens: TokenCollection;
  tokenEntries: TokenEntryCollection;
  sendToken?: Token;
  reissueToken?: Token;
}

const initialValue: ITokensReducer = {
  tokens: {},
  tokenEntries: {},
  sendToken: undefined,
  reissueToken: undefined,
};

const tokenTransfered = (state: ITokensReducer, tx: any): ITokensReducer => {
  const updatedTokens = { ...state.tokens };
  const { assetId } = tx;

  if (!updatedTokens[assetId]) {
    return {
      ...state,
      sendToken: undefined,
    };
  }

  let tokenCopy: Token = updatedTokens[assetId];

  if (tx.totalAmount) {
    // Mass transfer transaction
    tokenCopy.balance -= tx.totalAmount;
  } else {
    // Transfer transaction
    tokenCopy.balance -= tx.amount;
  }

  updatedTokens[assetId] = tokenCopy;

  return {
    ...state,
    tokens: updatedTokens,
    sendToken: undefined,
  };
};

const updateEntry = (
  entries: TokenEntryCollection,
  assetId: string,
  active: boolean
) => {
  const entriesCopy: TokenEntryCollection = { ...entries };

  const newEntry: TokenEntry = {
    assetId,
    active,
  };

  entriesCopy[assetId] = newEntry;

  return entriesCopy;
};

const tokenActivated = (
  state: ITokensReducer,
  action: AnyAction
): ITokensReducer => {
  return {
    ...state,
    tokenEntries: updateEntry(
      state.tokenEntries,
      action.payload.token.assetId,
      true
    ),
  };
};

const tokenDeactivated = (
  state: ITokensReducer,
  action: AnyAction
): ITokensReducer => {
  return {
    ...state,
    tokenEntries: updateEntry(
      state.tokenEntries,
      action.payload.token.assetId,
      false
    ),
  };
};

const tokenEntryDeleted = (state: ITokensReducer, action: AnyAction) => {
  const entriesCopy = { ...state.tokenEntries };
  delete entriesCopy[action.payload.tokenEntry.assetId];

  return {
    ...state,
    tokenEntries: entriesCopy,
  };
};

const tokenDeleted = (state: ITokensReducer, action: AnyAction) => {
  const { token } = action.payload;

  const tokensCopy = { ...state.tokens };
  delete tokensCopy[token.assetId];

  return {
    ...state,
    tokens: tokensCopy,
  };
};

const tokenUpdated = (
  state: ITokensReducer,
  action: AnyAction
): ITokensReducer => {
  const { token } = action.payload;

  const tokensCopy = { ...state.tokens };
  tokensCopy[token.assetId] = token;

  return {
    ...state,
    tokens: tokensCopy,
  };
};

const tokenReissued = (
  state: ITokensReducer,
  action: AnyAction
): ITokensReducer => {
  return {
    ...tokenUpdated(state, action),
    reissueToken: undefined,
  };
};

const tokensReducer = (
  state = initialValue,
  action: AnyAction
): ITokensReducer => {
  switch (action.type) {
    case TOKENS_FETCHED:
      return {
        ...state,
        ...action.payload,
      };
    case TOKEN_ENTRIES_FETCHED:
      return {
        ...state,
        ...action.payload,
      };
    case TOKEN_CREATED:
      return tokenUpdated(state, action);
    case TOKEN_ACTIVATED:
      return tokenActivated(state, action);
    case TOKEN_DEACTIVATED:
      return tokenDeactivated(state, action);
    case TOKEN_BURNED:
      return tokenDeleted(state, action);
    case TOKEN_SEND_INIT:
      return {
        ...state,
        sendToken: action.payload.token,
      };
    case TOKEN_SEND_CANCEL:
      return {
        ...state,
        sendToken: undefined,
      };
    case TOKEN_TRANSFERED:
      return tokenTransfered(state, action.payload.transaction);
    case TOKEN_ENTRY_DELETED:
      return tokenEntryDeleted(state, action);
    case TOKEN_ENTRY_ACTIVATED:
      return {
        ...state,
        tokenEntries: updateEntry(
          state.tokenEntries,
          action.payload.tokenEntry.assetId,
          true
        ),
      };
    case TOKEN_ENTRY_DEACTIVATED:
      return {
        ...state,
        tokenEntries: updateEntry(
          state.tokenEntries,
          action.payload.tokenEntry.assetId,
          false
        ),
      };
    case TOKEN_REISSUE_INIT:
      return {
        ...state,
        reissueToken: action.payload.token,
      };
    case TOKEN_REISSUE_CANCEL:
      return {
        ...state,
        reissueToken: undefined,
      };
    case TOKEN_REISSUED:
      return tokenReissued(state, action);
    default:
      return state;
  }
};

export default tokensReducer;
