import { User } from "../../../components/Users/User";

export const DEFAULT_TRANSACTION_FEE = 0.009;

const defaultFee = () => ({
  tokens: DEFAULT_TRANSACTION_FEE,
  assetId: "",
});

export const burnTransactionData = (
  assetId: string,
  amount: number
): WavesKeeper.TBurnTxData => {
  return {
    type: 6,
    data: {
      assetId: assetId,
      amount: amount,
      fee: defaultFee(),
    },
  };
};

export interface TransferParams {
  assetId: string;
  amount: number;
  address: string;
}

export const dataTransactionData = (
  data: WavesKeeper.TData[]
): WavesKeeper.TDataTxData => {
  return {
    type: 12,
    data: {
      data,
      version: 2,
      fee: defaultFee(),
    },
  } as any;
};

export const transferTransactionData = (
  params: TransferParams
): WavesKeeper.TTransferTxData => {
  const { assetId, amount, address } = params;
  return {
    type: 4,
    data: {
      amount: {
        assetId: assetId,
        tokens: amount,
      },
      recipient: address,
      fee: defaultFee(),
    },
  };
};

export const activateTransactionData = (
  assetId: string,
  active: boolean
): WavesKeeper.TDataTxData => {
  return dataTransactionData([
    {
      type: "string",
      key: `token_${assetId}`,
      value: active ? "active" : "inactive",
    },
  ]);
};

export const activateUserData = (
  address: string,
  active: boolean
): WavesKeeper.TDataTxData => {
  return dataTransactionData([
    {
      key: `user_${address}`,
      value: active ? "active" : "inactive",
      type: "string",
    },
  ]);
};

export const addUserData = (user: User): WavesKeeper.TDataTxData => {
  const entries: WavesKeeper.TData[] = [
    {
      key: `user_${user.address}`,
      type: "string",
      value: user.mobileId,
    },
  ];

  if (user.encNote) {
    entries.push({
      key: `user_note_${user.address}`,
      type: "string",
      value: user.encNote,
    });
  } else {
    entries.push({
      key: `user_note_${user.address}`,
      value: null,
    } as any);
  }

  return dataTransactionData(entries);
};

export const updateUserNote = (user: User): WavesKeeper.TDataTxData => {
  const entries: WavesKeeper.TData[] = [];

  return dataTransactionData(entries);
};

export const removeUserData = (address: string): WavesKeeper.TDataTxData => {
  return dataTransactionData([
    {
      key: `user_${address}`,
      value: null,
    },
    {
      key: `user_note_${address}`,
      value: null,
    },
  ] as any);
};

export const setScript = (script: string): WavesKeeper.TSetScriptTxData => {
  return {
    type: 13,
    data: {
      script,
      fee: {
        tokens: 0.014,
        assetId: "",
      },
    },
  };
};
