import { TFunction } from "i18next";
import { User } from "../User";
import AddressFormatter from "./Formatters/AddressFormatter";
import ActionsFormatter from "./Formatters/ActionsFormatter";
import { Column } from "react-table";
import { CellArgument } from "../../UI/MUITable/ITable";

// eslint-disable-next-line
const buildColumns = (users: User[], t: TFunction): Column<User>[] => {
  return [
    {
      Header: t("tables.users.columns.address") ?? "address",
      accessor: "address",
      Cell: (data: CellArgument<User>) => (
        <AddressFormatter user={data.row.original} />
      ),
    },
    {
      Header: t("tables.users.columns.mobileId") ?? "mobileId",
      accessor: "mobileId",
    },
    {
      Header: t("tables.users.columns.actions") ?? "actions",
      id: "actions",
      Cell: (data: CellArgument<User>) => (
        <ActionsFormatter user={data.row.original} />
      ),
    },
  ];
};

export default buildColumns;
