import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import KeysTable from "./KeysTable/KeysTable.component";

const Keys = (_props: any) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h4">{t("keys.title")}</Typography>
          <KeysTable />
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default Keys;
