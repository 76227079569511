import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "@mui/material";
import { useSnackbar } from "notistack";

import { IRootReducer } from "../../../reducers";
import Modal from "../../UI/Modal/Modal.component";
import {
  deviceKeysBurnCancel,
  deviceKeysBurnFinished,
  deviceKeysBurnPartiallyFinished,
} from "../../../actions/devicesActions";
import { Key, KeyCollection } from "../../Keys/Key";
import { useTranslation } from "react-i18next";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";

const ConfirmBurnDeviceKeysModal = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const keysBurn = useSelector((state: IRootReducer) => state.devices.keysBurn);
  const { writeService } = useKeeper();
  const { enqueueSnackbar } = useSnackbar();

  const onClose = () => {
    dispatch(deviceKeysBurnCancel());
  };

  const onConfirm = () => {
    if (!keysBurn || !writeService) return;
    const { device, keys } = keysBurn;

    const deletedKeys: KeyCollection = {};
    let allKeysDeleted: boolean = true;

    Promise.all(
      Object.values(keys).map(async (key: Key) => {
        await writeService
          .burnToken(key.assetId, 1)
          .then((res: any) => {
            deletedKeys[key.assetId] = key;
          })
          .catch((e: any) => {
            allKeysDeleted = false;
          });
      })
    ).then(() => {
      if (allKeysDeleted) {
        enqueueSnackbar(t("messages.devices.burnKeys.completed"), {
          variant: "success",
        });
        dispatch(deviceKeysBurnFinished(device, deletedKeys));
      } else if (Object.keys(deletedKeys).length > 0) {
        enqueueSnackbar(t("messages.devices.burnKeys.partCompleted"), {
          variant: "info",
        });
        dispatch(deviceKeysBurnPartiallyFinished(device, deletedKeys));
      } else {
        enqueueSnackbar(t("messages.devices.burnKeys.failed"), {
          variant: "error",
        });
        onClose();
      }
    });
  };

  const content = (
    <span>
      <h3>{t("messages.devices.burnKeys.confirmation")}</h3>
      <h5>{t("messages.devices.burnKeys.confirmationCd")}</h5>
    </span>
  );

  const footer = (
    <ButtonGroup>
      <Button variant="contained" color="success" onClick={onConfirm}>
        {t("buttons.confirm")}
      </Button>
      <Button variant="contained" color="error" onClick={onClose}>
        {t("buttons.deny")}
      </Button>
    </ButtonGroup>
  );

  return (
    <Modal
      closable={true}
      show={keysBurn !== undefined}
      handleClose={onClose}
      footer={footer}
    >
      {content}
    </Modal>
  );
};

export default ConfirmBurnDeviceKeysModal;
