import { Device } from "../../Device";
import KeyExpirationBadge from "../../../UI/KeyExpirationBadge/KeyExpirationBadge.component";

const ValidToFormatter = (props: { device: Device }) => {
  const { device } = props;
  const { key } = device;

  if (!key) {
    return <h1>No key</h1>;
  }

  return <KeyExpirationBadge deviceKey={key} />;
};

export default ValidToFormatter;
