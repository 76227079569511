import React from "react";
import { useState, useEffect } from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import { Translate } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface LanguageParams {
  name: string;
  flag: string;
}

// Flag codes are filenames of images in /public/flags
const languages: { [key: string]: LanguageParams } = {
  en: { name: "English", flag: "gb" },
  pl: { name: "Polski", flag: "pl" },
};

const setSelectedLanguage = (lng: string) => {
  localStorage.setItem("language", lng);
};

const getSelectedLanguage = () => {
  return localStorage.getItem("language");
};

const LanguageSelector = (props: any) => {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState<string>(
    getSelectedLanguage() || "en"
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClicked = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClosed = () => {
    setAnchorEl(null);
  };

  const switchLanguage = (lng: string) => {
    const lang = lng.substring(0, 2);
    setLanguage(lang);
    setSelectedLanguage(lang);
    handleMenuClosed();
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    document.title = t("interface.header.title");
  }, [t]);

  const buildFlag = (lng: LanguageParams) => {
    return (
      <img
        src={process.env.PUBLIC_URL + `/flags/${lng.flag}.png`}
        alt={lng.name}
      />
    );
  };

  const buildMenuItem = (lngCode: string, lngParams: LanguageParams) => {
    return (
      <MenuItem key={lngCode} onClick={() => switchLanguage(lngCode)}>
        <ListItemAvatar>{buildFlag(lngParams)}</ListItemAvatar>
        <ListItemText>{lngParams.name}</ListItemText>
      </MenuItem>
    );
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleMenuClicked}>
        <Tooltip title={t("interface.sidebar.language") || "Language"}>
          <ListItemIcon>
            <Translate />
          </ListItemIcon>
        </Tooltip>
        <ListItemText>
          {t("interface.sidebar.language", { defaultValue: "Language" })}
        </ListItemText>
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClosed}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {Object.entries(languages).map(([lngCode, lngParams]) =>
          buildMenuItem(lngCode, lngParams)
        )}
      </Menu>
    </React.Fragment>
  );
};

export default LanguageSelector;
