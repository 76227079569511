import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { IRootReducer } from "../reducers";
import { verifierChanged } from "../actions/wavesKeeperActions";
import { useKeeper } from "../providers/KeeperProvider/KeeperProvider";
import usePublicAddress from "./usePublicAddress";

// Workflow

// First verifier waits until WavesKeeper is successfully authorized
// Then it fetches the script of the account from the blockchain
// If it fails, informs the user about failed verification
// Compare fetched script with provided one
// If scripts match, verification is successful
// If scripts don't match, ask user if he wants to set the script
// User rejects, verification fails
// User accepts, then create a setScript transaction and send it to Waves Keeper
// If transaction goes through, verfification is successful
// Otherwise ask user if he wants to send it again

export type VerifierStatus =
  | "waiting" // waiting for WavesKeeper to verify account
  | "init" // Initialized
  | "scriptFetching" // fetching script data from blockchain
  | "scriptFetchFailed" // script fetch has failed
  | "pendingConfirm" // Awaiting confirmation from user
  | "pendingTransaction" // Transaction has been created, waiting for broadcast
  | "verified" // user is either confirmed or has broadcasted a transaction
  | "failedTransaction" // Transaction has failed
  | "denied" // User denied verification
  | "noAccount"; // Keeper does not have an account

const DAPPS_URL =
  "https://raw.githubusercontent.com/skey-network/skey-client-config/master/dapps.json";

const useOrgVerifier = () => {
  const dispatch = useDispatch();
  const { orgVerifier } = useSelector(
    (store: IRootReducer) => store.wavesKeeper
  );

  const { publicState, readService, writeService, status } = useKeeper();

  const [currentAddress, setCurrentAddress] = useState<string | undefined>(
    usePublicAddress()
  );
  const [orgScript, setOrgScript] = useState<string | undefined>();

  const [pendingTx, setPendingTx] = useState<boolean>(false);

  const setOrgVerified = useCallback(
    (newValue: VerifierStatus) => {
      if (orgVerifier === newValue) {
        return;
      }

      dispatch(verifierChanged(newValue));
    },
    [orgVerifier, dispatch]
  );

  const currentScript = useMemo(async (): Promise<string | undefined> => {
    const scriptUrl = await axios.get(DAPPS_URL).then((res) => {
      return res.data.scripts.organisation.url as string;
    });

    return await axios.get(scriptUrl).then((res) => {
      return res.data as string;
    });
  }, []);

  // init
  React.useEffect(() => {
    if (orgVerifier !== "init" || !publicState) return;

    setCurrentAddress(publicState.account?.address);
    setOrgVerified("waiting");
  }, [orgVerifier, publicState, setOrgVerified]);

  // waiting - script is not present
  React.useEffect(() => {
    if (orgVerifier !== "waiting" && !!orgScript) return;

    currentScript
      .then((script) => {
        setOrgScript(script);
      })
      .catch((e) => {
        setOrgVerified("scriptFetchFailed");
      });
  }, [orgVerifier, currentScript, orgScript, setOrgVerified]);

  // waiting - script is present
  React.useEffect(() => {
    if (orgVerifier !== "waiting" || !orgScript) return;

    if (!readService) {
      setOrgVerified("scriptFetchFailed");
      return;
    }

    readService
      .fetchAccountScript(currentAddress as string)
      .then((accountScript) => {
        if (accountScript === orgScript) {
          setOrgVerified("verified");
        } else {
          setOrgVerified("pendingConfirm");
        }
      });
  }, [
    status,
    orgVerifier,
    orgScript,
    publicState,
    currentAddress,
    readService,
    setOrgVerified,
  ]);

  // pendingTransaction
  React.useEffect(() => {
    if (orgVerifier !== "pendingTransaction" || !orgScript || pendingTx) return;

    setPendingTx(true);
    writeService
      ?.setScript(orgScript)
      .then(() => {
        setOrgVerified("verified");
      })
      .catch(() => {
        setOrgVerified("failedTransaction");
      })
      .finally(() => {
        setPendingTx(false);
      });
  }, [orgScript, orgVerifier, setOrgVerified, writeService, pendingTx]);

  useEffect(() => {
    const newAddress = publicState?.account?.address as string;

    if (!newAddress || newAddress.length === 0) {
      setCurrentAddress(undefined);
      setOrgVerified("noAccount");
    } else if (newAddress !== currentAddress) {
      setCurrentAddress(newAddress);
      setOrgVerified("waiting");
    }
  }, [publicState?.account, currentAddress, setOrgVerified]);

  return orgVerifier;
};

export default useOrgVerifier;
