import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { Provider } from "react-redux";
import store from "./store";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "./i18n";
import { KeeperProvider } from "./providers/KeeperProvider/KeeperProvider";
import { BlockchainNetworkProvider } from "./providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import { LayoutProvider } from "./providers/LayoutProvider/LayoutProvider";
import Spinner from "./components/UI/Spinner/Spinner.component";

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <LayoutProvider>
        <SnackbarProvider>
          <KeeperProvider connectOnLoad>
            <BlockchainNetworkProvider>
              <Router>
                <App />
              </Router>
            </BlockchainNetworkProvider>
          </KeeperProvider>
        </SnackbarProvider>
      </LayoutProvider>
    </Provider>
  </Suspense>,

  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
