import { FieldArrayRenderProps } from "formik";
import { IconButton, Tooltip } from "@mui/material";
import { GroupRemove } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const RemoveItem = (props: {
  arrayHelpers: FieldArrayRenderProps;
  index: number;
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("buttons.users.delete") || "Delete"}>
      <IconButton
        onClick={() => props.arrayHelpers.remove(props.index)}
        color="error"
      >
        <GroupRemove />
      </IconButton>
    </Tooltip>
  );
};

export default RemoveItem;
