import axios, { AxiosInstance } from "axios";

import {
  TokenEntry,
  TokenEntryCollection,
} from "../../components/Tokens/Token";
import { DataEntry } from "../../api/blockchain/blockchain";
import { User } from "../../components/Users/User";

export default class KeeperReadService {
  private serverUrl: string;
  private axiosInstance: AxiosInstance;

  constructor(serverUrl: string) {
    this.serverUrl = serverUrl;
    this.axiosInstance = axios.create({
      baseURL: this.nodeUrl,
    });
  }

  get nodeUrl(): string {
    return this.serverUrl + (this.serverUrl.match("/$") ? "" : "/");
  }

  async fetchAddressData(address: string, matches?: string) {
    return this.axiosInstance.get(`addresses/data/${address}`, {
      params: { matches },
    });
  }

  async fetchAccountScript(address: string): Promise<string | null> {
    return await this.axiosInstance
      .get(`addresses/scriptInfo/${address}`)
      .then((res) => {
        return res.data.script;
      });
  }

  async fetchAssets(address: string): Promise<any[]> {
    return await this.axiosInstance
      .get(`assets/balance/${address}`)
      .then((res) => {
        return res.data.balances;
      });
  }

  async fetchAllTokens(address: string): Promise<TokenEntryCollection> {
    return await this.axiosInstance
      .get(`addresses/data/${address}`, {
        params: {
          matches: "token_.{32,44}",
        },
      })
      .then((res: any) => {
        const dataEntries: DataEntry[] = res.data;

        const collection: TokenEntryCollection = {};

        dataEntries.forEach((entry: DataEntry) => {
          const tokenEntry: TokenEntry = {
            assetId: entry.key.replace("token_", ""),
            active: entry.value === "active",
          };
          collection[tokenEntry.assetId] = tokenEntry;
        });

        return collection;
      });
  }

  async getActiveTokens(address: string): Promise<TokenEntryCollection> {
    return await this.fetchAllTokens(address).then(
      (res: TokenEntryCollection) => {
        const collection: TokenEntryCollection = {};
        Object.values(res)
          .filter((rec) => rec.active)
          .forEach((rec) => {
            collection[rec.assetId] = rec;
          });

        return collection;
      }
    );
  }

  async fetchCurrentHeight(): Promise<number> {
    return await this.axiosInstance.get(`blocks/height`).then((res: any) => {
      return res.data.height;
    });
  }

  async getAssetDistribution(assetId: string, after?: string): Promise<object> {
    const currentHeight = await this.fetchCurrentHeight();

    const url = `assets/${assetId}/distribution/${
      currentHeight - 1
    }/limit/1000`;

    return await this.axiosInstance
      .get(url, { params: { after } })
      .then((res: any) => {
        return res.data.items;
      });
  }

  async getUsers(address: string): Promise<{ [key: string]: User }> {
    return await this.fetchAddressData(
      address,
      "user_.{35}|user_note_.{35}"
    ).then(async (res: any) => {
      const dataEntries: DataEntry[] = res.data;

      const users: { [key: string]: User } = {};
      const notes: { [key: string]: string } = {};

      dataEntries.forEach((value: DataEntry) => {
        if (value.key.match("user_note")) {
          const address = value.key.replace("user_note_", "");
          notes[address] = String(value.value);
        } else {
          const address = value.key.replace("user_", "");
          users[address] = {
            address,
            mobileId: String(value.value),
          };
        }
      });

      Object.entries(users).forEach(([address, user]) => {
        if (notes[address]) {
          user.encNote = notes[address];
        }
      });

      return users;
    });
  }

  async getUserNotes(address: string): Promise<{ [key: string]: string }> {
    return await this.fetchAddressData(address, "user_note_.{35}").then(
      (res: any) => {
        const dataEntries: DataEntry[] = res.data;

        const notes: { [key: string]: string } = {};

        dataEntries.forEach((value: DataEntry) => {
          const address = value.key.replace("user_note_", "");
          notes[address] = value.value as string;
        });

        return notes;
      }
    );
  }

  async getDAppFatherOrgEntry(
    dappFatherAddress: string,
    orgAddress: string
  ): Promise<boolean> {
    return await this.fetchAddressData(
      dappFatherAddress,
      `org_${orgAddress}`
    ).then((res: any) => {
      const dataEntries: DataEntry[] = res.data;

      return dataEntries.length > 0;
    });
  }
}
