import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../../UI/Modal/Modal.component";
import { IRootReducer } from "../../../reducers";
import { closeShowDeviceMap } from "../../../actions/devicesActions";
import DeviceMap from "../../Devices/DeviceMap/DeviceMap.component";
import { Button, ButtonGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const DeviceMapModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const device = useSelector((state: IRootReducer) => state.devices.showMap);

  let content = null;
  let footer = null;

  const onClose = () => {
    dispatch(closeShowDeviceMap());
  };

  if (device) {
    content = (
      <React.Fragment>
        <Typography variant="h3">{device.name}</Typography>
        <Typography variant="h6">{device.address}</Typography>
        <DeviceMap device={device} />
      </React.Fragment>
    );

    footer = (
      <React.Fragment>
        <ButtonGroup>
          <Button variant="contained" onClick={onClose}>
            {t("buttons.close")}
          </Button>
        </ButtonGroup>
      </React.Fragment>
    );
  }

  return (
    <Modal
      closable={true}
      show={device !== undefined}
      handleClose={onClose}
      footer={footer}
    >
      {content}
    </Modal>
  );
};

export default DeviceMapModal;
