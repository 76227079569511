import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import TokenEntryRow from "./TokenEntryRow.component";
import { TokenEntry } from "../Token";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";
import { Column } from "react-table";
import CustomTable from "../../UI/MUITable/CustomTable";
import { CellArgument } from "../../UI/MUITable/ITable";

export interface TokenEntryData {
  assetId: string;
  name: string;
  actions: JSX.Element;
}

const TokenEntriesTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tokens, tokenEntries } = useSelector(
    (state: IRootReducer) => state.tokens
  );
  const { writeService } = useKeeper();
  const { enqueueSnackbar } = useSnackbar();

  const renderedTokens = Object.values(tokenEntries).map(
    (tokenEntry: TokenEntry) => {
      return TokenEntryRow({
        tokenEntry,
        dispatch,
        writeService,
        t,
        token: tokens[tokenEntry.assetId],
        enqueueSnackbar,
      });
    }
  );

  // const expandRow: ExpandRowProps<TokenEntry> = {
  //   renderer: (row: TokenEntry) => {
  //     return <TokenDistribution assetId={row.assetId} />;
  //   },
  //   onlyOneExpanding: true,
  // };

  const columns: Column<TokenEntryData>[] = [
    {
      accessor: "name",
      Header: t("tables.tokenEntries.columns.name") ?? "name",
    },
    {
      accessor: "assetId",
      Header: t("tables.tokenEntries.columns.assetId") ?? "assetId",
    },
    {
      id: "actions",
      Header: t("tables.tokenEntries.columns.actions") ?? "actions",
      Cell: (arg: CellArgument<TokenEntryData>) => arg.row.original.actions,
    },
  ];

  return <CustomTable mode="local" data={renderedTokens} columns={columns} />;
};

export default TokenEntriesTable;
