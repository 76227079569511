import { TFunction } from "i18next";
import { Column } from "react-table";

import ActionsFormatter from "./Formatters/ActionsFormatter";
import DeviceFormatter from "./Formatters/DeviceFormatter";
import { Key } from "../Key";
import { CellArgument } from "../../UI/MUITable/ITable";
import KeyExpirationBadge from "../../UI/KeyExpirationBadge/KeyExpirationBadge.component";

const buildColumns = (t: TFunction): Column<Key>[] => {
  return [
    {
      accessor: "name",
      Header: t("tables.keys.columns.name") ?? "name",
    },
    {
      accessor: "assetId",
      Header: t("tables.keys.columns.assetId") ?? "assetId",
    },
    {
      accessor: "validTo",
      Header: t("tables.keys.columns.expiresAt") ?? "expiresAt",
      Cell: (arg: CellArgument<Key>) => {
        return <KeyExpirationBadge deviceKey={arg.row.original} />;
      },
    },
    {
      accessor: "deviceName",
      Header: t("tables.keys.columns.deviceName") ?? "deviceName",
      Cell: (arg: CellArgument<Key>) => (
        <DeviceFormatter row={arg.row.original} />
      ),
    },
    {
      id: "actions",
      Header: t("tables.keys.columns.actions") ?? "actions",
      Cell: (arg: CellArgument<Key>) => (
        <ActionsFormatter row={arg.row.original} />
      ),
    },
  ];
};

export default buildColumns;
