import { ISendTokenForm, Recipient } from "./SendTokenForm.component";
import { calculateTotalAmount } from "./helpers";

const calculateTxFee = (recipients: Recipient[]): number => {
  return 0.009 + recipients.length * 0.0005;
};

const buildMassTransferTxData = (
  values: ISendTokenForm
): WavesKeeper.TMassTransferTxData => {
  return {
    type: 11,
    data: {
      transfers: values.recipients.map((recipient) => ({
        recipient: recipient.address,
        amount: recipient.amount,
      })),
      totalAmount: {
        assetId: values.assetId,
        tokens: calculateTotalAmount(values.recipients),
      },
      fee: {
        assetId: "WAVES",
        tokens: calculateTxFee(values.recipients),
      },
    },
  };
};

const buildTransferTxData = (
  values: ISendTokenForm
): WavesKeeper.TTransferTxData => {
  return {
    type: 4,
    data: {
      recipient: values.recipients[0].address,
      amount: {
        assetId: values.assetId,
        tokens: values.recipients[0].amount,
      },
      fee: {
        assetId: "WAVES",
        tokens: 0.009,
      },
    },
  };
};

const buildTxData = (values: ISendTokenForm) => {
  if (values.recipients.length > 1) {
    return buildMassTransferTxData(values);
  } else {
    return buildTransferTxData(values);
  }
};

export default buildTxData;
