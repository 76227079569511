import { Tooltip, IconButton } from "@mui/material";
import { showDeviceMap } from "../../../../actions/devicesActions";
import { Room } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Device } from "../../Device";
import { useBlockchainNetwork } from "../../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";

const ShowMapButton = (props: { device: Device }): JSX.Element => {
  const dispatch = useDispatch();
  const { device } = props;
  const { read } = useBlockchainNetwork();
  const { t } = useTranslation();

  const onShowMapClicked = () => {
    if (!read) return;
    dispatch(showDeviceMap(read, device.address));
  };

  return (
    <Tooltip title={t("tables.devices.actions.showOnMap") || "Show on map"}>
      <IconButton onClick={onShowMapClicked} color="primary">
        <Room />
      </IconButton>
    </Tooltip>
  );
};

export default ShowMapButton;
