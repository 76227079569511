import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { IRootReducer } from "../../../reducers";
import { usersFetched } from "../../../actions/usersActions";
import { UserCollection } from "../User";
import render from "./Renderer";
import useStatusVerifier from "../../../hooks/useStatusVerifier";
import usePublicAddress from "../../../hooks/usePublicAddress";
import Spinner from "../../UI/Spinner/Spinner.component";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";

const UsersTable = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState<boolean>(false);

  const { data } = useSelector((state: IRootReducer) => state.users);
  const publicAddress = usePublicAddress();
  const orgVerified = useStatusVerifier();
  const { readService } = useKeeper();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!readService || !publicAddress || fetched) return;

    readService
      .getUsers(publicAddress)
      .then(async (res: UserCollection) => {
        dispatch(usersFetched(res));
      })
      .catch((e) => {
        enqueueSnackbar(
          t("messages.users.fetchFailed", {
            message: e.message,
          }),
          { variant: "error" }
        );
      })
      .finally(() => {
        setFetched(true);
      });
  }, [
    readService,
    publicAddress,
    orgVerified,
    dispatch,
    t,
    enqueueSnackbar,
    fetched,
  ]);

  useEffect(() => {
    setFetched(false);
  }, [publicAddress]);

  if (!fetched)
    return (
      <div>
        <Spinner />
      </div>
    );

  return render(Object.values(data), t, props.showModal);
};

export default UsersTable;
