import {
  WAVES_KEEPER_STATUS_CHANGED,
  WAVES_KEEPER_STATE_CHANGED,
  ORG_VERIFIER_STATUS_CHANGED,
} from "./types";
import { AuthStatus } from "../hooks/useWavesKeeper";
import { VerifierStatus } from "../hooks/useOrgVerifier";
import { ActionCreator, Action } from "redux";

export const statusChanged: ActionCreator<Action> = (
  authStatus: AuthStatus
) => {
  return {
    type: WAVES_KEEPER_STATUS_CHANGED,
    payload: { authStatus },
  };
};

export const stateChanged: ActionCreator<Action> = (
  publicState: WavesKeeper.IPublicStateResponse | undefined
) => {
  return {
    type: WAVES_KEEPER_STATE_CHANGED,
    payload: { publicState },
  };
};

export const verifierChanged: ActionCreator<Action> = (
  orgVerifier: VerifierStatus
) => {
  return {
    type: ORG_VERIFIER_STATUS_CHANGED,
    payload: { orgVerifier },
  };
};
